import * as Yup from 'yup';

export const initialData = {
  value: 0,
  title: [],
  description: '',
};

export const validationSchema = Yup.object().shape({
  value: Yup.number().min(1),
});
