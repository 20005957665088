import { FC, useEffect, useRef, useState } from 'react';

import { DescriptionWithShowMoreProps } from './DescriptionWithShowMore.props';

const DescriptionWithShowMoreView: FC<DescriptionWithShowMoreProps> = ({
  value,
  maxLines = 3,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight,
        10,
      );
      const maxHeight = lineHeight * maxLines + 2;
      setIsOverflowing(textRef.current.scrollHeight > maxHeight);
    }
  }, [value, maxLines]);

  return (
    <div>
      <span
        ref={textRef}
        className="font-secondary text-md text-gray-700"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'unset' : maxLines,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}>
        {value}
      </span>
      {isOverflowing && (
        <button onClick={toggleExpanded} className="text-blue-500 text-sm">
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

export default DescriptionWithShowMoreView;
