import { forwardRef } from 'react';

import AlertIcon from 'components/icons/AlertIcon';
import Button from 'components/primitives/Button';
import CommonModal from '../CommonModal';
import { CommonModalRef } from '../CommonModal/CommonModal.props';
import { AlertModalProps } from './AlertModal.props';

const AlertModalView = forwardRef<CommonModalRef, AlertModalProps>(
  (props, ref) => {
    return (
      <CommonModal
        ref={ref}
        closeOnBackdropClick={props.closeOnBackdropClick ?? true}
        isCloseButton={false}
        onSuccessFullyClosed={(e) => {
          e.stopPropagation(); // Stop event propagation
          props.onClose && props.onClose();
        }}
        isDefaultOpen={props.defaultOpen ?? false}
        className="w-[24.5rem] md:w-[37.5rem] flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 py-8">
          <div className="flex justify-center">
            {props.icon ?? <AlertIcon />}
          </div>
          <div className="text-center text-gray-700 text-base font-semibold font-primary capitalize">
            {props.title ?? ''}
          </div>
          <p className="md:w-64 text-center text-gray-500 text-sm font-secondary">
            {props.description}
          </p>
          <div>{props.children}</div>
          <Button
            isLoading={props.buttonIsLoading}
            id="open-btn"
            variant="solid"
            type={props.buttonType}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation
              props.onClickButton && props.onClickButton();
            }}
            colorScheme="primary"
            className="mt-5">
            {props.buttonTitle ?? 'Close'}
          </Button>
        </div>
      </CommonModal>
    );
  },
);

export default AlertModalView;
