import { FC, useCallback, useState } from 'react';

import { CreateItemViewProps } from './CreateItem.props';

import View from 'components/core/View/View.view';
import FormikInputNumber from 'components/form/FormikInputNumber';
import ImagePicker from 'components/modules/ImagePicker';
import { ImageUploadHelper } from 'components/modules/ImagePicker/helpers/imageUploadHelper';
import UserLocationForm from 'components/modules/UserLocationForm';
import Button from 'components/primitives/Button';
import CheckBox from 'components/primitives/CheckBox';
import SelectInput from 'components/primitives/SelectInput';
import SelectOption from 'components/primitives/SelectOption';
import TextAreaInput from 'components/primitives/TextAreaInput';
import TextInput from 'components/primitives/TextInput';
import { routesSettings } from 'constants/routes';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { $user } from 'state/stores/user';
import AddInludeInputBuilder from './IncludesInputBuilder.view';
import {
  deliveryOptions,
  generateInitialData,
  validationSchema,
} from './utils';

const CreateItemView: FC<CreateItemViewProps> = ({
  onCancel,
  categories,
  onChangeCategory,
  subCategories,
  onSubmit,
  creationInProgress,
}) => {
  const [images, setImages] = useState<(string | File)[]>([]);
  const [featuredImage, setFeaturedImage] = useState<string>('');

  const userDetail = $user.selectors.useUser();
  const onChange = useCallback(
    (images: (string | File)[], featuredImage: string) => {
      setImages(images);
      setFeaturedImage(featuredImage);
    },
    [],
  );

  return (
    <View.Container>
      <div className="flex flex-col justify-center">
        <h1 className="items-center justify-center text-center">Create Item</h1>
        <Formik
          initialValues={generateInitialData(userDetail!)}
          // onSubmit={(values) => onSubmit && onSubmit(values)}
          onSubmit={(values, { setFieldValue, setFieldError }) => {
            let data = { ...values } as any;
            data['featuredPhoto'] = featuredImage;
            data['photos'] = images;

            const imageUploadHelper = new ImageUploadHelper(
              images,
              featuredImage,
            );

            const isValid = imageUploadHelper.validate((message) => {
              setFieldError('photos', message);
            });

            if (!isValid) return;

            onSubmit && onSubmit(data);
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}>
          {({ values, setFieldValue, setErrors }) => (
            <Form>
              <div className="lg:flex justify-between gap-12 mt-8">
                <div className="w-full grid grid-col-2 gap-x-3">
                  <div className="col-span-2">
                    <TextInput
                      key="title"
                      name="title"
                      placeholder="Example: Powerhorse Electric Pressure Washer — 3000 PSI 2.0 GPM Model 113880"
                      label="Title"
                      isRequired
                    />
                  </div>
                  <div>
                    <SelectInput
                      key="category"
                      disabled={categories.length == 0 ? true : false}
                      name="category"
                      onChange={onChangeCategory}
                      placeholder="Select category..."
                      label="Category"
                      tooltipMessage="Select the category of your item, could be “tools” “outdoors”, etc."
                      isRequired>
                      {categories.length > 0 && (
                        <option value="">Select Category</option>
                      )}
                      {categories.map((category, index) => (
                        <SelectOption
                          key={`category_key_${index}`}
                          value={category._id}
                          child={category.name}
                        />
                      ))}
                    </SelectInput>
                  </div>
                  <div>
                    <SelectInput
                      key="subcategory"
                      disabled={subCategories.length == 0 ? true : false}
                      name="subCategory"
                      placeholder="Select sub-category..."
                      label="Sub-Category"
                      tooltipMessage="The subcategory is referring to.."
                      isRequired>
                      {subCategories.length > 0 && (
                        <option>Select Sub Category</option>
                      )}
                      {subCategories.map((category, index) => (
                        <SelectOption
                          key={category._id + index}
                          value={category._id}
                          child={category.name}
                        />
                      ))}
                    </SelectInput>
                  </div>
                  <div className="w-full">
                    <TextInput
                      key="modelNumber"
                      name="modelNumber"
                      placeholder="Enter model number..."
                      label="Model Number"
                      isRequired
                      tooltipMessage="The model or model number of your item"
                    />
                  </div>
                  <div className="w-full">
                    <TextInput
                      name="serialNumber"
                      key="serialNumber"
                      placeholder="Enter serial number or barcode..."
                      label="Serial Number Or Barcode"
                      tooltipMessage="You can find this number on the item or on the box"
                    />
                  </div>

                  <div className="col-span-2">
                    <UserLocationForm formModeOff={true} cityName="location" />
                  </div>

                  <div className="col-span-2">
                    <TextAreaInput
                      isRequired
                      key="description"
                      name="description"
                      label="Description"
                      placeholder="Provide key specs..."
                    />
                  </div>
                  <div className="flex flex-col">
                    <FormikInputNumber
                      key="rentalPrice"
                      placeholder="Enter rental price..."
                      label="Rental Price ($/Per Day)"
                      name="rentalPrice"
                      type="currency"
                      min={5}
                      max={250}
                    />
                    <span className="text-sm">
                      The recommended price per day is around 10% of the item's
                      retail price.
                    </span>
                  </div>
                  <div>
                    <AddInludeInputBuilder />
                  </div>
                </div>
                <div className="w-full">
                  <div className="col-span-2">
                    <ImagePicker
                      isRequired
                      limit={5}
                      toolTipMessage="After uploading please select a featured image"
                      onChange={onChange}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-red-500 text-sm mt-2"
                      name={'photos'}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-red-500 text-sm mt-2"
                      name={'featuredPhoto'}
                    />
                  </div>

                  <div className="mt-[14px]">
                    <TextAreaInput
                      key="guidelines"
                      name="guidelines"
                      label="Guidelines"
                      placeholder="Example: Item includes a wood cutting blade. Not intended for cutting metal or plastics. Not available on Sundays"
                    />
                  </div>

                  <div>
                    <TextInput
                      key="ownersManual"
                      name="ownersManual"
                      placeholder="Enter owner’s manual link..."
                      label="Link to owner’s manual"
                      tooltipMessage="The manufacturer manual or any manual that helps the renter to use the item"
                    />
                  </div>
                  <div className="w-full md:w-[60%] ">
                    <CheckBox
                      key="deliveryOptions"
                      name="deliveryOptions"
                      label="Pickup/Delivery Preferences"
                      isRequired>
                      {deliveryOptions.map((option) => {
                        return (
                          <div key={option.name}>
                            <div className="flex flex-row items-center gap-4 border-primary-500">
                              <Field type="checkbox" name={option.name}>
                                {({ field }: FieldProps) => {
                                  return (
                                    <input
                                      {...field}
                                      type="checkbox"
                                      className="checkbox"
                                    />
                                  );
                                }}
                              </Field>
                              <span>{option.label}</span>
                            </div>
                          </div>
                        );
                      })}
                    </CheckBox>
                  </div>
                </div>
              </div>

              <div className="px-10 flex flex-col gap-10 mt-4">
                <div className="flex gap-2 justify-center">
                  <Field type="checkbox" name="termsAndConditions" />
                  <span className="text-gray-700">
                    By creating the item you agree to the
                  </span>
                  <a
                    href={routesSettings.MAIN_TERMS_AND_CONDITIONS.path}
                    target="_blank">
                    <span className="text-[#3769CC]">terms and conditions</span>
                  </a>
                </div>
                <div className="flex md:flex-row flex-col justify-center md:gap-10 gap-2">
                  <Button
                    id="open-btn"
                    type="button"
                    onClick={onCancel}
                    variant={'outline'}
                    colorScheme={'secondary'}
                    size="w-64">
                    Cancel
                  </Button>
                  <Button
                    id="open-btn"
                    type="submit"
                    isLoading={creationInProgress}
                    variant={'solid'}
                    colorScheme={'primary'}
                    isDisabled={!values.termsAndConditions}
                    size="w-64">
                    Create Item
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </View.Container>
  );
};

export default CreateItemView;
