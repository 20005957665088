import moment from 'moment';

export function simplifyDate(dateTimeString?: string): string {
  const date = moment.utc(dateTimeString ?? '').format('MMMM Do YYYY');
  const day = date.split(' ')[1];
  return date.replace(day, day);
}

export function formatDate(dateTimeString?: string): string {
  const date = new Date(dateTimeString ?? '');
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
}

export function getYearFromDateString(dateTimeString?: string): string {
  const date = new Date(dateTimeString ?? '');
  return date.getFullYear().toString();
}

export function isDateBetween(startDate: Date, endDate: Date): boolean {
  const currentDate = new Date();
  return startDate <= currentDate && currentDate <= endDate;
}

export const convertUTCToLocalTime = (utcDateString: string): string => {
  const utcDate = new Date(utcDateString);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const localDateString = utcDate.toLocaleString('en-US', options);
  return localDateString; // Removing the comma for better readability
};
