export function generateKeyFrom(value: string) {
  let hash = 0;
  if (value.length == 0) return hash;

  for (let i = 0; i < value.length; i++) {
    let char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash.toString();
}

/**
 * Checks if a URL starts with 'http://' or 'https://', and adds 'http://' if not.
 * Validates the URL to ensure it's well-formed.
 *
 * @param {string} url - The URL to check and validate.
 * @returns {string | null} - Returns the properly formatted URL or null if invalid.
 */
export function validateAndFormatUrl(url: string): string | null {
  // Add http:// if the URL does not start with http:// or https://
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }

  // Validate the URL using the URL constructor
  try {
    const validUrl = new URL(url);
    return validUrl.href;
  } catch (e) {
    return '#'; // Invalid URL
  }
}

export function openUrlInNewTab(url: string): void {
  // Add http:// if the URL does not start with http:// or https://
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }

  // Validate the URL using the URL constructor
  try {
    const validUrl = new URL(url);
    window.open(validUrl.href, '_blank', 'noopener,noreferrer');
  } catch (e) {
    console.error('Invalid URL:', url);
  }
}

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

export function formatAmount(num: number): string {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
}
