import { FC, useEffect } from 'react';

import usePhaseStore from 'state/atoms/useIndexPhase';
import { useHeaderContext } from 'state/contexts/headerContext';
import { FormikPhase } from 'types/formik';
import { AccountCreatedViewProps } from './AccountCreated.props';
import AccountSuccessMessage from './steps/AccountSuccessMessage';
import StripeConnectivity from './steps/StripeConnectivity';
import UserCitySelection from './steps/UserCitySelection';

const AccountCreatedView: FC<AccountCreatedViewProps> = ({}) => {
  const { hideAuthButtons } = useHeaderContext();

  useEffect(() => {
    hideAuthButtons();
  }, []);

  const phases: FormikPhase[] = [
    {
      key: 'account-success',
      title: 'Account successfully created!',
      render: () => <AccountSuccessMessage />,
    },
    {
      key: 'select-city',
      title: 'Select your city',
      render: () => <UserCitySelection />,
    },
    {
      key: 'connect-stripe',
      title: 'Connect to stripe',
      render: () => <StripeConnectivity />,
    },
  ];

  const { indexPhase } = usePhaseStore();

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex flex-col h-full justify-center">
        {phases[indexPhase].render()}
      </div>
    </div>
  );
};

export default AccountCreatedView;
