import { FC } from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { ListCardProps } from './ListCard.props';

const ListCardView: FC<ListCardProps> = ({
  title,
  children,
  itemNotFoundPlaceholder,
}) => {
  const count = children as unknown as any;
  const isEmpty = !count?.length;
  const isMobile = useIsMobile();
  return (
    <div
      className={
        isMobile
          ? 'w-full'
          : 'min-h-min w-full px-9 my-10 md:px-5 grid grid-flow-row place-items-center'
      }>
      <div className={isMobile ? 'flex flex-col gap-10' : 'w-fit'}>
        <div
          className={
            isMobile ? 'flex-between items-center' : 'flex-between items-center'
          }>
          {title && (
            <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
              {title}
            </p>
          )}
        </div>
        {isEmpty && (
          <div className="flex flex-wrap justify-center gap-10">
            {itemNotFoundPlaceholder}
          </div>
        )}
        <div
          className={
            !isMobile
              ? 'flex flex-wrap justify-center gap-10'
              : 'grid grid-cols-3 gap-x-4 gap-y-4 p-4'
          }>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ListCardView;
