import ClockIcon from 'components/icons/ClockIcon';
import { useEffect, useState } from 'react';

interface TimerProps {
  expiresAt: number;
  onExpire?: () => void;
}

const Timer = ({ expiresAt, onExpire }: TimerProps) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [hasExpired, setHasExpired] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remaining = expiresAt - now;

      if (remaining <= 0) {
        setTimeRemaining('00:00:00');
        clearInterval(timer);
        if (!hasExpired) {
          setHasExpired(true);
          onExpire?.();
        }
        return;
      }

      const hours = Math.floor(remaining / 3600);
      const minutes = Math.floor((remaining % 3600) / 60);
      const seconds = remaining % 60;

      setTimeRemaining(
        `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [expiresAt, hasExpired, onExpire]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        padding: '0.5rem 0.75rem',
        borderRadius: '6px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '16px',
        maxWidth: '200px',
        margin: '0 auto 16px',
        gap: '8px',
      }}>
      <ClockIcon width="24" height="24" />
      <div
        style={{
          fontFamily: 'monospace',
          fontSize: '1rem',
          fontWeight: 'bold',
          color: timeRemaining === '00:00:00' ? '#E74C3C' : '#2ECC71',
        }}>
        {timeRemaining}
      </div>
    </div>
  );
};

export default Timer;
