import { FC } from 'react';

import Card from 'components/primitives/Card';

import homeImg from 'assets/images/home_new_image.jpeg';
import useIsMobile from 'hooks/useIsMobile';

import MobileMenuHeader from 'components/modules/MobileMenuHeader';
import ListCard from 'components/primitives/ListCard';
import Loading from 'components/primitives/Loading';
import Pagination from 'components/primitives/Pagination';
import NoCategoryItemsFound from 'components/vectors/NoCategoryItemsFound';
import NoItemsFound from 'components/vectors/NoItemsFound';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { $itemRentDetails } from 'state/stores/rent-details';
import rentalColors from 'styles/colors';
import { Product } from 'types/item';
import { getNetworkImage } from 'utils/assets';
import { RentViewProps } from './Rent.props';

const RentView: FC<RentViewProps> = ({
  categories,
  isLoading,
  products,
  filteredProducts,
  selectedCategoryId,
  onSearch,
  onCategoryChange,
  total,
  totalPages,
  currentPage,
  setPage,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const onCardClick = async (id?: string) => {
    $itemRentDetails.actions.reset();
    navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(id!), {
      replace: false,
    });
  };

  const handleCategoryClick = (categoryId: string) => {
    if (onCategoryChange) {
      onCategoryChange(categoryId);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="h-full pb-16 md:pb-0">
        <section>
          <div
            className="rent-header-img"
            style={{
              backgroundImage: `url(${homeImg})`,
              color: rentalColors.secondary[900],
            }}>
            <div className="w-full opacity-60 rounded-b-3xl h-28 md:h-72 md:rounded-none bg-cover bg-no-repeat bg-center bg-primary-500"></div>
            <div>
              <form>
                <label className="w-80 md:w-96">
                  <div className="bg-white absolute w-1/2 top-20 md:top-80 left-1/4 rounded-lg">
                    <input
                      onChange={(e) => onSearch && onSearch(e.target.value)}
                      className="input w-full  border border-gray-300"
                      placeholder="Search"
                    />
                  </div>
                </label>
              </form>
            </div>
          </div>
        </section>
        {isMobile && (
          <div className="absolute right-0 -top-1 my-4 mr-7 z-10 block">
            <MobileMenuHeader />
          </div>
        )}
        {/* <div className="h-auto w-full px-5 flex justify-start bg-primary-500 my-9">
          <div className="flex flex-col justify-center mb-9 items-start px-16 2xl:px-24">
            <div className="flex justify-center  gap-2 items-end mt-4">
              <p className="text-white text-3xl font-semibold font-primary">
                What you previously rented
              </p>
              <button
                onClick={() => navigate('/lend', { state: { activeTab: 1 } })}>
                <p className="text-white text-xlg font-normal font-primary h-full">
                  See all
                </p>
              </button>
            </div>
            <div className="flex flex-row flex-wrap gap-10 mt-6 flex-shrink-0">
              {rentHistoryQuery?.map((item: History, index: number) => {
                return (
                  <div key={index} className="card-item rounded-lg">
                    <img
                      src={getNetworkImage(item.item.photos[0])}
                      className="rounded-lg w-32 h-32 align-middle"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        <section
          className={
            isMobile
              ? 'flex flex-row overflow-x-auto mt-12 whitespace-nowrap scrollbar-hidden gap-2'
              : 'flex flex-row flex-wrap justify-center gap-4 px-9 md:px-24 cursor-pointer overflow-x-auto mt-12'
          }>
          <div
            key={`category-all`}
            onClick={() => handleCategoryClick('')}
            className={`badge badge-primary-100 ${selectedCategoryId == '' ? 'bg-primary-300 text-primary-900' : 'bg-primary-50 text-primary-900'} p-3 text-sm border-none font-primary font-bold capitalize`}>
            All ({products?.length})
          </div>
          {categories?.map((category, index) => {
            const isCategorySelected = selectedCategoryId === category._id;
            const colorSchemeBasedOnSelection = isCategorySelected
              ? 'bg-primary-300 text-primary-900'
              : 'bg-primary-50 text-primary-900';
            return (
              <div
                key={`category-${category._id}-${index}`}
                onClick={() => handleCategoryClick(category._id)}
                className={`badge badge-primary-100 p-3 ${colorSchemeBasedOnSelection} text-sm border-none font-primary font-bold capitalize`}>
                {category.name}
              </div>
            );
          })}
        </section>

        <ListCard
          itemNotFoundPlaceholder={
            selectedCategoryId ? (
              <NoCategoryItemsFound w="95%" />
            ) : (
              <NoItemsFound
                w="95%"
                description="We couldn’t find any results
matching your search criteria. Try adjusting your search terms or check back soon."
              />
            )
          }>
          {filteredProducts?.map((product: Product, index: any) => (
            <Card
              key={`${product.title}-${index}`}
              onCardClick={() => onCardClick(product._id)}
              isMobile={isMobile}
              img={getNetworkImage(product.featuredPhoto ?? product.photos[0])}
              title={product.title}
              id={product._id}
              description={product.description}
            />
          ))}
        </ListCard>
        <div className="flex justify-end px-32 pb-12">
          <Pagination
            totalPages={totalPages!}
            currentPageNo={currentPage!}
            onPageChange={function (page: number): void {
              setPage && setPage(page);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RentView;
