import { FC } from 'react';

import Card from 'components/primitives/Card';
import Loading from 'components/primitives/Loading';
import { routesSettings } from 'constants/routes';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { TopRent } from 'types/rent';
import { getNetworkImage } from 'utils/assets';
import { TopItemSectionViewProps } from './TopItemSection.props';

const TopItemSectionView: FC<TopItemSectionViewProps> = ({
  isLoading,
  data,
}) => {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const onCardClick = (id?: string) => {
    navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(id!), {
      replace: false,
    });
  };

  const cardsTobeShown = (list: TopRent[]) => {
    return list.slice(0, 5);
  };

  return (
    <div
      className={
        isMobile
          ? 'w-full my-5 px-4'
          : 'min-h-min w-full px-9 my-10 md:px-24 grid grid-flow-row place-items-center'
      }>
      <div className={isMobile ? 'flex flex-col gap-3' : 'w-fit'}>
        <div className="flex-between">
          <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
            Top Items
          </p>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div
              className={
                isMobile
                  ? 'grid grid-cols-2 gap-x-4 gap-y-4 p-4'
                  : 'flex flex-wrap justify-center gap-10'
              }>
              {cardsTobeShown(data!).map((topItem, index) => {
                const item = topItem.topItem;
                return (
                  <Card
                    key={`${item._id}-${index}`}
                    img={getNetworkImage(item.featuredPhoto ?? item?.photos[0])}
                    title={item.title}
                    description={item.description}
                    isMobile={isMobile}
                    onCardClick={() => onCardClick(item._id)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopItemSectionView;
