import img1 from 'assets/images/img1.webp';
import img2 from 'assets/images/img2.webp';
import img3 from 'assets/images/img3.webp';
import img4 from 'assets/images/img4.webp';
import img5 from 'assets/images/img5.webp';

import joeImg from 'assets/images/Joe.png';
import maryImg from 'assets/images/Mary.png';

import tipsImg1 from 'assets/images/tips_img0.png';
import tipsImg2 from 'assets/images/tips_img1.png';
import tipsImg3 from 'assets/images/tips_img2.png';
import tipsImg4 from 'assets/images/tips_img3.png';
import tipsImg5 from 'assets/images/tips_img4.png';

import tableimg1 from 'assets/images/tableImages/Rectangle 174.png';
import tableimg2 from 'assets/images/tableImages/Rectangle 176.png';

import firstStepImg from 'assets/images/02_Business_startup.webp';
import secondStepImg from 'assets/images/15_HR_manager.webp';
import fifthStepImg from 'assets/images/27_Stock_market.png';
import fourthStepImg from 'assets/images/28_Teamwork.webp';
import thirdtepImg from 'assets/images/30_Video_conference.webp';

import { Product } from 'types';

import img11 from 'assets/images/img1.webp';
import img22 from 'assets/images/img2.webp';
import img33 from 'assets/images/img3.webp';
import img44 from 'assets/images/img4.webp';
import img55 from 'assets/images/img5.webp';

export const introContent = [
  {
    title: 'Welcome onboard!',
    text: `We're thrilled to have you on board. Rapid Rentals is all about
  making renting and lending easier and more enjoyable. Let's take
  you through the options you have on our platform.`,
    buttonContent1: 'Get started!',
    buttonContent2: '',
    id: 'item1',
    img: firstStepImg,
  },
  {
    title: 'Rent',
    text: `Browse through our diverse range of categories and rent what you need. Easily keep track of your rental history to stay organized and efficient.`,
    buttonContent1: 'Continue',
    buttonContent2: 'Go back',
    id: 'item2',
    img: secondStepImg,
  },
  {
    title: 'Lend',
    text: `Have items you're not using all the time? Share them with our community and earn some extra income. Listing your items is quick and simple, and you're in control of your rental terms. List with confidence as all items include $1000 in coverage via our RRPP (Rapid Rentals Protection Plan)`,
    buttonContent1: 'Continue',
    buttonContent2: 'Go back',
    id: 'item6',
    img: fifthStepImg,
  },
  {
    title: 'Communicate',
    text: `A smooth transaction is key. Stay in touch with the item owner or your customer through our convenient SMS chat bot during the duration of your rental`,
    buttonContent1: 'Continue',
    buttonContent2: 'Go back',
    id: 'item3',
    img: thirdtepImg,
  },
  {
    title: 'Have Fun!',
    text: `Rapid Rentals is designed to enhance your lifestyle and bring more convenience to your everyday life. So go ahead, have fun and make the most of your experience!`,
    buttonContent1: 'Finish',
    buttonContent2: 'Go back',
    id: 'finish-btn',
    id2: 'item4',
    img: fourthStepImg,
  },
];

export const cardsSectionTitles = [
  'Top Categories',
  'Top Items',
  'Tips & Tricks.',
];

export const items = [
  {
    image: img1,
    title: 'Name of item',
    description: 'Description of the item',
  },
  {
    image: img2,
    title: 'Name of item',
    description: 'Description of the item',
  },
  {
    image: img3,
    title: 'Name of item',
    description: 'Description of the item',
  },
  {
    image: img4,
    title: 'Name of item',
    description: 'Description of the item',
  },
  {
    image: img5,
    title: 'Name of item',
    description: 'Description of the item',
  },
];

export const tips = [
  {
    image: tipsImg1,
    title: 'How to start selling?',
    description: 'Start selling it shouldn’t be that...',
  },
  {
    image: tipsImg2,
    title: 'How to start selling?',
    description: 'Start selling it shouldn’t be that...',
  },
  {
    image: tipsImg3,
    title: 'How to start selling?',
    description: 'Start selling it shouldn’t be that...',
  },
  {
    image: tipsImg4,
    title: 'How to start selling?',
    description: 'Start selling it shouldn’t be that...',
  },
  {
    image: tipsImg5,
    title: 'How to start selling?',
    description: 'Start selling it shouldn’t be that...',
  },
];
export const rentCategories = [
  'Category (345)',
  'Category (345)',
  'Category (345)',
  'Category (345)',
  'Category (345)',
  'Category (345)',
  'Category (345)',
  'Category (345)',
];

export const tableHeader = ['Image', 'Name', 'Category', 'Status'];

export const ProductRateInfo = [
  {
    rate: 4.8,
    rateByFeature: [
      { title: 'Battery', value: '4.0' },
      { title: 'Power', value: '4.8' },
      { title: 'Precision', value: '4.5' },
      { title: 'Functionality', value: '4.2' },
      { title: 'Price Quality', value: '4.0' },
    ],
    revies: [
      {
        description: `Great product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 10, 2024',
        recommend: true,
      },
      {
        description: `Not bad, but could be better. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 12, 2024',
        recommend: false,
      },
      {
        description: `Awesome precision! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 15, 2024',
        recommend: true,
      },
    ],
    productId: '02',
    id: '0201',
  },
  {
    rate: 4.5,
    rateByFeature: [
      { title: 'Battery', value: '3.8' },
      { title: 'Power', value: '4.2' },
      { title: 'Precision', value: '4.0' },
      { title: 'Functionality', value: '4.5' },
      { title: 'Price Quality', value: '4.7' },
    ],
    reviews: [
      {
        description: `Solid performance overall. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 18, 2024',
        recommend: true,
      },
      {
        description: `Decent features, but the price is a bit high. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 20, 2024',
        recommend: false,
      },
      {
        description: `Impressive functionality! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 22, 2024',
        recommend: true,
      },
    ],
    productId: '03',
    id: '0301',
  },
  {
    rate: 4.2,
    rateByFeature: [
      { title: 'Battery', value: '3.5' },
      { title: 'Power', value: '4.0' },
      { title: 'Precision', value: '4.2' },
      { title: 'Functionality', value: '3.8' },
      { title: 'Price Quality', value: '4.5' },
    ],
    reviews: [
      {
        description: `Satisfactory performance. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 25, 2024',
        recommend: true,
      },
      {
        description: `Average product. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 28, 2024',
        recommend: false,
      },
      {
        description: `Could use some improvements. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'January 30, 2024',
        recommend: false,
      },
    ],
    productId: '04',
    id: '0401',
  },
  {
    rate: 4.9,
    rateByFeature: [
      { title: 'Battery', value: '4.8' },
      { title: 'Power', value: '5.0' },
      { title: 'Precision', value: '4.9' },
      { title: 'Functionality', value: '4.7' },
      { title: 'Price Quality', value: '4.8' },
    ],
    reviews: [
      {
        description: `Exceptional product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 2, 2024',
        recommend: true,
      },
      {
        description: `Well worth the investment. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 5, 2024',
        recommend: true,
      },
      {
        description: `Top-notch precision and features. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 8, 2024',
        recommend: true,
      },
    ],
    productId: '05',
    id: '0501',
  },
  {
    rate: 3.7,
    rateByFeature: [
      { title: 'Battery', value: '3.0' },
      { title: 'Power', value: '3.5' },
      { title: 'Precision', value: '3.8' },
      { title: 'Functionality', value: '3.0' },
      { title: 'Price Quality', value: '4.2' },
    ],
    reviews: [
      {
        description: `Needs improvement. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 10, 2024',
        recommend: false,
      },
      {
        description: `Not satisfied with the performance. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 12, 2024',
        recommend: false,
      },
      {
        description: `Good value for the price. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
        date: 'February 15, 2024',
        recommend: true,
      },
    ],
    productId: '06',
    id: '0601',
  },
];

export const questions = [
  {
    avatar: joeImg,
    question: `Hi there! I'm interested in borrowing an electric drill. Can you provide more details about the available options? `,
    id: '010101',
  },
  {
    avatar: maryImg,
    question: `I prefer a cordless drill for convenience. What's the battery life like on the cordless drill, and does it come with extra batteries?`,
    answer: `The cordless drill comes with a long-lasting lithium-ion battery that provides approximately 4-6 hours of continuous usage. We can provide an additional fully charged battery to ensure unint...`,
    answerDate: '10/04/2023',
    id: '010102',
  },
  {
    avatar: joeImg,
    question: `Hi there! I'm interested in borrowing an electric drill. Can you provide more details about the available options? `,
    answer: `Hello! Sure, we have several electric drills available for loan. Can you specify the type of project you're working on and any specific requirements you have for the drill?`,
    answerDate: '10/04/2023',
    id: '010101',
  },
  {
    avatar: maryImg,
    question: `I prefer a cordless drill for convenience. What's the battery life like on the cordless drill, and does it come with extra batteries?`,
    answer: `The cordless drill comes with a long-lasting lithium-ion battery that provides approximately 4-6 hours of continuous usage. We can provide an additional fully charged battery to ensure unint...`,
    answerDate: '10/04/2023',
    id: '010102',
  },
  {
    avatar: joeImg,
    question: `Hi there! I'm interested in borrowing an electric drill. Can you provide more details about the available options? `,
    answer: `Hello! Sure, we have several electric drills available for loan. Can you specify the type of project you're working on and any specific requirements you have for the drill?`,
    answerDate: '10/04/2023',
    id: '010101',
  },
  {
    avatar: maryImg,
    question: `I prefer a cordless drill for convenience. What's the battery life like on the cordless drill, and does it come with extra batteries?`,
    answer: `The cordless drill comes with a long-lasting lithium-ion battery that provides approximately 4-6 hours of continuous usage. We can provide an additional fully charged battery to ensure unint...`,
    answerDate: '10/04/2023',
    id: '010102',
  },
  {
    avatar: joeImg,
    question: `Hi there! I'm interested in borrowing an electric drill. Can you provide more details about the available options? `,
    answer: `Hello! Sure, we have several electric drills available for loan. Can you specify the type of project you're working on and any specific requirements you have for the drill?`,
    answerDate: '10/04/2023',
    id: '010101',
  },
  {
    avatar: maryImg,
    question: `I prefer a cordless drill for convenience. What's the battery life like on the cordless drill, and does it come with extra batteries?`,
    answer: `The cordless drill comes with a long-lasting lithium-ion battery that provides approximately 4-6 hours of continuous usage. We can provide an additional fully charged battery to ensure unint...`,
    answerDate: '10/04/2023',
    id: '010102',
  },
];

export const gridPhotosArray = [
  img11,
  img22,
  img33,
  img44,
  img55,
  img22,
  img33,
  img44,
  img55,
];

export const products: Array<Product> = [
  {
    title: 'Powerful Hammer',
    owner: 'Jane Smith',
    ownerId: '1',
    status: 'Available',
    price: '35',
    photos: [img11, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '1',
      },
    ],
    description: `The Powerful Hammer is your go-to tool for heavy-duty construction work. With its robust design and high impact force, it's perfect for driving nails into tough materials. Whether you're a professional contractor or a DIY enthusiast, this hammer will get the job done efficiently.`,
    rate: 4.5,
    link: 'www.powerfulhammer.com',
    id: '02',
    productRateInfo: {
      rate: 4.8,
      rateByFeature: [
        { title: 'Battery', value: '4.0' },
        { title: 'Power', value: '4.8' },
        { title: 'Precision', value: '4.5' },
        { title: 'Functionality', value: '4.2' },
        { title: 'Price Quality', value: '4.0' },
      ],
      reviews: [
        {
          description: `Great product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 10, 2024',
          recommend: true,
        },
        {
          description: `Not bad, but could be better. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 12, 2024',
          recommend: false,
        },
        {
          description: `Awesome precision! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 15, 2024',
          recommend: true,
        },
      ],
      productId: '02',
      id: '0201',
    },
  },
  {
    title: 'Precision Screwdriver Set',
    owner: 'Alex Rodriguez',
    ownerId: '2',
    status: 'Available',
    price: '18',
    photos: [tableimg2, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '2',
      },
      {
        categoryId: '3',
      },
    ],
    description: `The Precision Screwdriver Set is a must-have for anyone working with electronics or delicate hardware. With a variety of interchangeable tips, this set provides the precision you need for intricate tasks. It's compact, durable, and the go-to choice for professionals and hobbyists alike.`,
    rate: 4.8,
    link: 'www.precisiontools.com',
    id: '03',
    productRateInfo: {
      rate: 4.5,
      rateByFeature: [
        { title: 'Battery', value: '3.8' },
        { title: 'Power', value: '4.2' },
        { title: 'Precision', value: '4.0' },
        { title: 'Functionality', value: '4.5' },
        { title: 'Price Quality', value: '4.7' },
      ],
      reviews: [
        {
          description: `Solid performance overall. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 18, 2024',
          recommend: true,
        },
        {
          description: `Decent features, but the price is a bit high. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 20, 2024',
          recommend: false,
        },
        {
          description: `Impressive functionality! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 22, 2024',
          recommend: true,
        },
      ],
      productId: '03',
      id: '0301',
    },
  },
  {
    title: 'Professional Paintbrush Set',
    owner: 'Carlos Gonzalez',
    ownerId: '3',
    status: 'Available',
    price: '28',
    photos: [tableimg1, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '1',
      },
    ],
    description: `Elevate your painting experience with our Professional Paintbrush Set. Crafted with high-quality bristles and ergonomic handles, these brushes ensure smooth and precise strokes. Whether you're an aspiring artist or a seasoned painter, this set is a valuable addition to your creative toolkit.`,
    rate: 4.7,
    link: 'www.paintbrushpros.com',
    id: '04',
    productRateInfo: {
      rate: 4.2,
      rateByFeature: [
        { title: 'Battery', value: '3.5' },
        { title: 'Power', value: '4.0' },
        { title: 'Precision', value: '4.2' },
        { title: 'Functionality', value: '3.8' },
        { title: 'Price Quality', value: '4.5' },
      ],
      reviews: [
        {
          description: `Satisfactory performance. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 25, 2024',
          recommend: true,
        },
        {
          description: `Average product. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 28, 2024',
          recommend: false,
        },
        {
          description: `Could use some improvements. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'January 30, 2024',
          recommend: false,
        },
      ],
      productId: '04',
      id: '0401',
    },
  },
  {
    title: 'Compact Circular Saw',
    owner: 'Emily White',
    ownerId: '4',
    status: 'Available',
    price: '55',
    photos: [tableimg2, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '3',
      },
      {
        categoryId: '4',
      },
      {
        categoryId: '3',
      },
    ],
    description: `Introducing the Compact Circular Saw, a game-changer in woodworking. Its lightweight and compact design make it easy to maneuver, while the powerful motor ensures precise cuts. Whether you're a professional carpenter or a DIY enthusiast, this saw is a reliable companion for your projects.`,
    rate: 4.9,
    link: 'www.compactsawhub.com',
    id: '05',
    productRateInfo: {
      rate: 4.9,
      rateByFeature: [
        { title: 'Battery', value: '4.8' },
        { title: 'Power', value: '5.0' },
        { title: 'Precision', value: '4.9' },
        { title: 'Functionality', value: '4.7' },
        { title: 'Price Quality', value: '4.8' },
      ],
      reviews: [
        {
          description: `Exceptional product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 2, 2024',
          recommend: true,
        },
        {
          description: `Well worth the investment. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 5, 2024',
          recommend: true,
        },
        {
          description: `Top-notch precision and features. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 8, 2024',
          recommend: true,
        },
      ],
      productId: '05',
      id: '0501',
    },
  },
  {
    title: 'Smart Home Security System',
    owner: 'David Johnson',
    ownerId: '5',
    status: 'Available',
    price: '120',
    photos: [tableimg1, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '1',
      },
    ],
    description:
      'Secure your home with our Smart Home Security System. Easy to install and packed with features, this system provides comprehensive protection for your property. Monitor your home remotely, receive real-time alerts, and enjoy peace of mind knowing your home is safeguarded with cutting-edge technology.',
    rate: 4.6,
    link: 'www.smarthomesecurity.com',
    id: '06',
    productRateInfo: {
      rate: 3.7,
      rateByFeature: [
        { title: 'Battery', value: '3.0' },
        { title: 'Power', value: '3.5' },
        { title: 'Precision', value: '3.8' },
        { title: 'Functionality', value: '3.0' },
        { title: 'Price Quality', value: '4.2' },
      ],
      reviews: [
        {
          description: `Needs improvement. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 10, 2024',
          recommend: false,
        },
        {
          description: `Not satisfied with the performance. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 12, 2024',
          recommend: false,
        },
        {
          description: `Good value for the price. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 15, 2024',
          recommend: true,
        },
      ],
      productId: '06',
      id: '0601',
    },
  },
  {
    title: 'Gourmet Coffee Maker',
    owner: 'Sophia Lee',
    ownerId: '6',
    status: 'Available',
    price: '75',
    photos: [tableimg2, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '1',
      },
      {
        categoryId: '2',
      },
    ],
    description: `
      Indulge in the art of coffee brewing with our Gourmet Coffee Maker.
      This high-end appliance is designed for coffee enthusiasts who appreciate the perfect cup.
      With customizable settings and sleek design, it adds a touch of sophistication to your kitchen.
      Elevate your coffee experience with this premium coffee maker.
    `,
    rate: 4.8,
    link: 'www.gourmetcoffeemaker.com',
    id: '07',
    productRateInfo: {
      rate: 4.9,
      rateByFeature: [
        { title: 'Battery', value: '4.8' },
        { title: 'Power', value: '5.0' },
        { title: 'Precision', value: '4.9' },
        { title: 'Functionality', value: '4.7' },
        { title: 'Price Quality', value: '4.8' },
      ],
      reviews: [
        {
          description: `Exceptional product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 2, 2024',
          recommend: true,
        },
        {
          description: `Well worth the investment. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 5, 2024',
          recommend: true,
        },
        {
          description: `Top-notch precision and features. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 8, 2024',
          recommend: true,
        },
      ],
      productId: '07',
      id: '0501',
    },
  },
  {
    title: 'Fitness Tracker Watch',
    owner: 'Michael Brown',
    ownerId: '7',
    status: 'Available',
    price: '50',
    photos: [tableimg1, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '3',
      },
      {
        categoryId: '3',
      },
    ],
    description: `
      Stay on top of your fitness goals with our Fitness Tracker Watch.
      This sleek wearable device monitors your activity, tracks your workouts, and provides insights into your health.
      With a stylish design and smart features, it's the perfect companion for an active and healthy lifestyle.
    `,
    rate: 4.6,
    link: 'www.fitnesstrackerhub.com',
    id: '08',
    productRateInfo: {
      rate: 4.9,
      rateByFeature: [
        { title: 'Battery', value: '4.8' },
        { title: 'Power', value: '5.0' },
        { title: 'Precision', value: '4.9' },
        { title: 'Functionality', value: '4.7' },
        { title: 'Price Quality', value: '4.8' },
      ],
      reviews: [
        {
          description: `Exceptional product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 2, 2024',
          recommend: true,
        },
        {
          description: `Well worth the investment. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 5, 2024',
          recommend: true,
        },
        {
          description: `Top-notch precision and features. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 8, 2024',
          recommend: true,
        },
      ],
      productId: '08',
      id: '0501',
    },
  },
  {
    title: 'Luxury Leather Wallet',
    owner: 'Olivia Taylor',
    ownerId: '8',
    status: 'Available',
    price: '90',
    photos: [tableimg2, img2, img3, img4, img5],
    productCategories: [
      {
        categoryId: '1',
      },
    ],
    description: `
      Upgrade your style with our Luxury Leather Wallet.
      Meticulously crafted from premium leather, this wallet exudes sophistication and durability.
      With ample card slots and a sleek design, it's a statement accessory for those who appreciate the finer things in life.
    `,
    rate: 4.9,
    link: 'www.luxuryleatherwallets.com',
    id: '09',
    productRateInfo: {
      rate: 4.9,
      rateByFeature: [
        { title: 'Battery', value: '4.8' },
        { title: 'Power', value: '5.0' },
        { title: 'Precision', value: '4.9' },
        { title: 'Functionality', value: '4.7' },
        { title: 'Price Quality', value: '4.8' },
      ],
      reviews: [
        {
          description: `Exceptional product! Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 2, 2024',
          recommend: true,
        },
        {
          description: `Well worth the investment. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 5, 2024',
          recommend: true,
        },
        {
          description: `Top-notch precision and features. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
          date: 'February 8, 2024',
          recommend: true,
        },
      ],
      productId: '09',
      id: '0501',
    },
  },
];

export const categories = [
  {
    title: 'Tools',
    bg: '#E5EAF4',
    text: '#2478C5',
    photo: img1,
  },
  { title: 'Electronics', bg: '#EFE5F4', text: '#8824C5', photo: img2 },
  { title: 'DIY', bg: '#F4EEE5', text: '#C57124', photo: img3 },
  { title: 'Compact', bg: '#D2F5E1', text: '#22CC7A', photo: img5 },
];

export const rateNumber = [
  { number: '1', progress: 1 },
  {
    number: '2',
    progress: 10,
  },
  { number: '3', progress: 60 },
  { number: '4', progress: 0 },
  { number: '5', progress: 35 },
];
