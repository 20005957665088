import { IconProps } from './type';

const HamburgerIcon = (props: IconProps) => {
  return (
    <svg
      className="cursor-pointer"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="none" />
      <path
        d="M3 12h18M3 6h18M3 18h18"
        stroke={props.color ?? 'black'}
        strokeWidth="2"
      />
    </svg>
  );
};

export default HamburgerIcon;
