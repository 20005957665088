import { FC } from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { limitCharacters } from 'utils/limitCharacters';
import { CardProps } from './Card.props';

const CardView: FC<CardProps> = (props) => {
  const { description, img, id, title, onCardClick } = props;
  const isMobile = useIsMobile();
  return (
    <div
      className={
        isMobile
          ? 'card card-item rounded-lg'
          : 'card card-item mt-6 w-16 rounded-lg overflow-hidden'
      }
      onClick={() => onCardClick && onCardClick()}>
      <div className="object-cover">
        <img
          loading="lazy"
          src={img}
          alt={title}
          className="select-none object-cover h-32 w-full rounded-md"
          draggable={false}
        />
      </div>
      <div className={isMobile ? 'm-2 px-2' : 'card-body'}>
        <p className="font-primary truncate max-w-xs text-gray-700 text-sm font-bold md:text-lg md:text-start capitalize ...">
          {isMobile ? limitCharacters(title, 30) : title}
        </p>
        {description && !isMobile && (
          <div className="flex items-start justify-start text-gray-500 text-sm font-normal font-secondary">
            {isMobile
              ? limitCharacters(description, 30)
              : limitCharacters(description, 70)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardView;
