import { QUERY_KEYS } from 'api/queries';
import HamburgerIcon from 'components/icons/HamburgurIcon';
import { routesSettings } from 'constants/routes';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { $session } from 'state/stores';
import { $itemRentDetails } from 'state/stores/rent-details';
import { $stripe } from 'state/stores/stripe';
import { $user } from 'state/stores/user';
import { MobileMenuHeaderProps } from './MobileMenuHeader.props';

const MobileMenuHeaderView: FC<MobileMenuHeaderProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);

  // Close the dropdown when ref changes

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    $session.actions.clearSession();
    $user.actions.clearUserData();
    $stripe.actions.reset();
    $itemRentDetails.actions.reset();
    await QUERY_KEYS.invalidateAll();
    navigate(routesSettings.AUTH_LOGIN.path);
    setIsDropdownOpen(false);
  };

  const navigateToHistory = () => {
    navigate(routesSettings.LEND.path, {
      state: {
        activeTab: 2,
      },
    });
    setIsDropdownOpen(false);
  };
  return (
    <div
      className="dropdown dropdown-bottom rounded z-10"
      onClick={toggleDropdown}>
      <div
        tabIndex={0}
        role="button"
        className="bg-white px-2 rounded text-sm p-1 cursor-pointer">
        <HamburgerIcon h="12" w="12" />
      </div>
      <ul
        tabIndex={0}
        ref={dropdownRef}
        style={{
          visibility: isDropdownOpen ? 'visible' : 'hidden',
        }}
        className="dropdown-content menu p-1 flex flex-col items-center justify-center shadow top-[65%] bg-white rounded-lg w-24 -right-6 ">
        <li onClick={navigateToHistory}>
          <h5>Activity</h5>
        </li>
        <li
          onClick={handleLogout}
          className="text-red-500 hover:bg-red-100 rounded-lg ">
          <h5 className="text-red-700 hover:text-red-500">Logout</h5>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenuHeaderView;
