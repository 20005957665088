import { FC } from 'react';

import FormikRating from 'components/form/FormikRating';
import { ReviewHelper } from 'helperClasses/reviews';
import useItemReviews from 'hooks/useItemReviews';
import { $itemRentDetails } from 'state/stores/rent-details';
import { ProductRatingProps } from './ProductRating.props';

const ProductRatingView: FC<ProductRatingProps> = (props) => {
  const isOwnersProduct = $itemRentDetails.selectors.isOwnersItem();

  const product = $itemRentDetails.selectors.useProduct();
  const { reviews } = useItemReviews(product?._id!);
  const reviewHelper = ReviewHelper;
  const averageRate = reviewHelper.findAverageRate(reviews);
  if (isOwnersProduct) return null;
  if (averageRate === 0) return null;
  return (
    <div className="flex justify-start gap-2">
      <h2 className="text-3xl text-[#737373] font-[600]">
        {averageRate?.toFixed(1) ?? 0}
      </h2>
      <FormikRating.RatingWidget
        size="22"
        value={averageRate != null ? Math.round(averageRate) : 0}
      />
    </div>
  );
};

export default ProductRatingView;
