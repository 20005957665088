import CheckIcon from 'components/icons/CheckIcon';
import Button from 'components/primitives/Button';
import usePhaseStore from 'state/atoms/useIndexPhase';

const AccountSuccessMessage = () => {
  const { nextPhase, indexPhase } = usePhaseStore();
  return (
    <div className="space-y-8 flex flex-col items-center">
      <div className="flex flex-col items-center w-full">
        <CheckIcon w="80" h="80" />
        <div className="mt-2"></div>
        <h2 className="my-2">Account successfully created!</h2>
        <p className="text-center font-secondary mt-1 md:w-96">
          Fantastic! Your account is all set. Dive into a world of tools,
          accessories, gadgets, and more. Start exploring and experiencing the
          possibilities today!
        </p>
      </div>
      <div className="flex justify-center">
        <Button onClick={nextPhase} variant={'solid'}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default AccountSuccessMessage;
