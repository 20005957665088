import { FC, useRef } from 'react';

import useRateRenterMutation from 'api/mutations/useRateRenterMutation';
import FormikRating from 'components/form/FormikRating';
import FormikTextArea from 'components/form/FormikTextArea';
import CommonModal from 'components/modals/CommonModal';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import SuccessModal from 'components/modals/SuccessModal';
import Button from 'components/primitives/Button';
import { Form, Formik } from 'formik';
import { ReviewPayload } from 'types/reviews';
import { RateRenterProps } from './RateRenter.props';
import { initialData, validationSchema } from './RateRenter.utils';

const RateRenterView: FC<RateRenterProps> = ({
  child,
  renterId,
  itemId,
  rentId,
}) => {
  const modalRef = useRef<CommonModalRef>(null);
  const successModalRef = useRef<CommonModalRef>(null);

  const showRatingDialog = () => {
    modalRef.current?.onOpen();
  };

  const { mutate: rate, isPending } = useRateRenterMutation((data: any) => {
    onSuccess();
  });

  const onSubmit = (data: typeof initialData) => {
    //mutation will go here

    const renterCaracteristics: any = [];

    const payload: ReviewPayload = {
      reviewerId: renterId,
      itemId: itemId,
      rentId: rentId,
      description: data.description,
      rate: data.value,
      renterCaracteristics: renterCaracteristics,
    };

    rate(payload);
  };

  const onSuccess = () => {
    modalRef.current?.onClose!();
    successModalRef.current?.onOpen();
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={showRatingDialog}>
        {child}
      </div>
      <SuccessModal
        title="Feedback Sent!"
        description={`We're thrilled to have you on board. Rapid Rentals is all about making renting and lending easier and more enjoyable. Let's take you through the options you have on our platform.`}
        ref={successModalRef}
      />
      <CommonModal ref={modalRef}>
        <div className="px-8 lg:px-60 py-10 w-full flex flex-col justify-center items-center space-y-8">
          <h1 className="text-3xl">Rate the renter</h1>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialData}
            validationSchema={validationSchema}>
            {({ isValid }) => {
              return (
                <Form className="space-y-8 flex flex-col justify-center items-center">
                  <FormikRating name="value" />
                  <FormikTextArea
                    name="description"
                    placeholder="Here you can explain better"
                  />
                  <Button
                    isDisabled={!isValid}
                    variant="solid"
                    isLoading={isPending}
                    colorScheme="primary">
                    Send feedback
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </CommonModal>
    </div>
  );
};

export default RateRenterView;
