import useUserProfile from 'api/queries/useUserProfile';
import HomeIcon from 'components/icons/HomeIcon';
import LendIcon from 'components/icons/LendIcon';
import ProfileIcon from 'components/icons/ProfileIcon';
import RentIcon from 'components/icons/RentIcon';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { $session } from 'state/stores';
import { $user } from 'state/stores/user';
import { MobileMenuProps } from './MobileMenu.props';

const MobileMenuView: FC<MobileMenuProps> = () => {
  const location = useLocation();
  const fullName = $user.selectors.getFullName();
  const userId = $session.actions.getUserId();

  const { profileQuery } = useUserProfile({
    userId,
  });

  $user.actions.setAddress(profileQuery?.address!);

  // Function to check if the menu item is active
  const isActive = (path: string): boolean => {
    return location.pathname === path || location.pathname.includes(`${path}/`);
  };

  return (
    <>
      <ul className="menu fixed bottom-0 left-0 w-full flex flex-row bg-primary-50 items-center justify-around gap-4 border-t border-gray-200 z-50">
        <Link
          to="/home"
          className="flex flex-col items-center"
          role="tab"
          aria-label="/home">
          <div>
            <HomeIcon fill={isActive('/home') ? '#22CC7A' : ''} />
          </div>
          <h6
            className="text-neutral-400"
            style={{ color: isActive('/home') ? '#22CC7A' : '' }}>
            Home
          </h6>
        </Link>
        <Link
          to="/rent"
          className="flex flex-col items-center"
          role="tab"
          aria-label="/rent">
          <div>
            <RentIcon fill={isActive('/rent') ? '#22CC7A' : ''} />
          </div>
          <h6
            className="text-neutral-400"
            style={{ color: isActive('/rent') ? '#22CC7A' : '' }}>
            Rent
          </h6>
        </Link>
        <Link
          to="/lend"
          className="flex flex-col items-center"
          role="tab"
          aria-label="/lend">
          <div>
            <LendIcon fill={isActive('/lend') ? '#22CC7A' : ''} />
          </div>
          <h6
            className="text-neutral-400"
            style={{ color: isActive('/lend') ? '#22CC7A' : '' }}>
            Lend
          </h6>
        </Link>
        <Link
          to="/profile"
          className="flex flex-col items-center"
          role="tab"
          aria-label="/profile">
          <div>
            <ProfileIcon fill={isActive('/profile') ? '#22CC7A' : ''} />
          </div>
          <h6
            className="text-neutral-400"
            style={{ color: isActive('/profile') ? '#22CC7A' : '' }}>
            Profile
          </h6>
        </Link>
      </ul>
    </>
  );
};

export default MobileMenuView;
