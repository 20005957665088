import LeftArrowIcon from 'components/icons/LeftArrow';
import RentalsIcon from 'components/icons/RentalsIcon';
import { useNavigate } from 'react-router-dom';
import greenLogo from '../../../assets/images/green.png';

export const MobileHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="join w-full flex justify-start bg-slate-50">
      <button className="join-item w-full btn flex justify-start bg-slate-50">
        <div
          onClick={() => {
            navigate(-1);
          }}>
          <LeftArrowIcon fill={'#22CC7A'} />
        </div>
        <img src={greenLogo} />

        <RentalsIcon />
      </button>
    </div>
  );
};
