import { FC } from 'react';

import { ErrorMessage, Field, FieldProps } from 'formik';
import Label from '../Label';
import { SelectInputProps } from './SelectInput.props';

const SelectInputView: FC<SelectInputProps> = (props) => {
  const {
    label,
    isRequired,
    name,
    children,
    tooltipMessage,
    isDisabled,
    defaultValue,
    onChange,
  } = props;
  return (
    <>
      {label && (
        <Label
          name={name}
          isRequired={isRequired}
          toolTipMessage={tooltipMessage}
          title={label!}
        />
      )}

      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <select
            {...field}
            disabled={isDisabled}
            defaultValue={defaultValue}
            onChange={(e) => {
              field.onChange(e);
              form.setFieldValue(name, e.target.value);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            className="select select-ghost border-1 focus:outline-primary border-gray-300 border-opacity-1 w-full capitalize">
            {children}
          </select>
        )}
      </Field>
      <div className="h-6 mt-2">
        <ErrorMessage
          component="div"
          className="text-red-500 text-sm"
          name={name}
        />
      </div>
    </>
  );
};

export default SelectInputView;
