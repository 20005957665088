import { FC } from 'react';

import Card from 'components/primitives/Card';
import Loading from 'components/primitives/Loading';
import { routesSettings } from 'constants/routes';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { TopCategory } from 'types/category';
import { CategorySectionViewProps } from './CategorySection.props';

const CategorySectionView: FC<CategorySectionViewProps> = ({
  isLoading,
  data,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const cardsTobeShown = (list: TopCategory[]) => {
    return list;
  };

  const onClickCategory = (categoryId: string) => {
    navigate(`${routesSettings.RENT.getPath()}?categoryId=${categoryId}`, {
      replace: false,
    });
  };

  return (
    <div
      className={
        isMobile
          ? 'w-full my-5 px-4'
          : 'min-h-min w-full px-9 my-10 md:px-24 grid grid-flow-row place-items-center'
      }>
      <div className={isMobile ? 'flex flex-col gap-3' : 'w-fit'}>
        <div className={'flex-between items-center'}>
          <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
            Top Categories
          </p>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div
              className={
                isMobile
                  ? 'grid grid-cols-2 gap-x-4 gap-y-4 p-4'
                  : 'flex flex-wrap justify-center gap-10'
              }>
              {cardsTobeShown(data).map((topCategory, index) => {
                const item = topCategory.category;
                return (
                  <Card
                    key={`${item._id}-${index}`}
                    img={item.image}
                    title={item.name}
                    description={item.description}
                    isMobile={isMobile}
                    onCardClick={() => onClickCategory(item._id)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySectionView;
