import { FC } from 'react';

import images from 'assets/images';
import { useRentItemStore } from 'state/atoms';
import { getNetworkImage } from 'utils/assets';
import { formatAmount } from 'utils/common';
import { simplifyDate } from 'utils/date';
import { calculatePrice } from 'utils/product';
import { TotalPurchaseProps } from './TotalPurchase.props';

const TotalPurchaseView: FC<TotalPurchaseProps> = ({ product }) => {
  var [{ startDate, endDate }] = useRentItemStore();

  const startDateTime = new Date(startDate!);
  const endDateTime = new Date(endDate!);

  const { tax, actualRentalPrice, totalPrice, daysDifference } = calculatePrice(
    product?.price!,
    startDateTime,
    endDateTime,
  );

  return (
    <div className="flex flex-col w-full">
      <div className="grid md:grid-cols-12 grid-flow-row gap-4 items-center justify-center">
        <div className="col-span-2 md:col-span-1">
          <img
            className="md:w-12 md:h-12 w-24 h-24 rounded object-cover"
            src={getNetworkImage(product?.featuredPhoto ?? product?.photos[0])}
          />
        </div>
        <div className="col-span-7">
          <div className="">
            <h5 className="capitalize">{product?.title}</h5>
            <div className="text-gray-700 text-sm font-normal font-secondary leading-tight">
              {`Rental duration from ${simplifyDate(startDate!)} to ${simplifyDate(endDate!)}`}
            </div>
          </div>
        </div>
        <div className="col-span-5 md:col-span-2">
          <div className="text-primary-500 text-base font-semibold font-primary">
            ${product?.price}/day x {daysDifference} days
          </div>
        </div>
        <div className="col-span-2 flex justify-end">
          ${formatAmount(actualRentalPrice)}
        </div>
      </div>
      {/* <div className="divider" />
      <div className="grid grid-cols-12 gap-4 items-center justify-center">
        <div className="col-span-1">
          <ShieldIcon />
        </div>
        <div className="col-span-9">
          <div className="">
            <div className="text-neutral-700 text-base font-semibold font-['Montserrat']">
              {product?.title} Security Deposit
            </div>
            <span className="text-neutral-700 text-sm font-normal font-['Open Sans'] leading-tight">
              Security hold deposit for security reasons.
            </span>
          </div>
        </div>
        <div className="col-span-2 flex justify-end">$50</div>
      </div> */}
      <div className="divider" />
      <div className="grid md:grid-cols-12 grid-flow-row gap-4 items-center justify-center">
        <div className="col-span-1">
          <img
            src={images.taxAndServices}
            alt=""
            style={{
              width: 'md:56px 100px',
              height: 'md:56px 100px',
            }}
          />
        </div>
        <div className="col-span-9">
          <div className="">
            <div className="text-neutral-700 text-base font-semibold font-['Montserrat']">
              Tax and Services
            </div>
            <span className="text-neutral-700 text-sm font-normal font-['Open Sans'] leading-tight">
              This fee covers all state and local taxes, credit card processing
              fees, as well as Rapid Rentals Protection Plan (RRPP)
            </span>
          </div>
        </div>
        <div className="md:col-span-2 col-span-9 flex justify-end">
          ${formatAmount(tax)}
        </div>
      </div>
      <div className="divider" />
      <div className="text-right text-neutral-700 text-2xl font-bold font-['Montserrat']">
        Total: ${formatAmount(totalPrice)}
      </div>
    </div>
  );
};

export default TotalPurchaseView;
