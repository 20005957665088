import create from 'zustand';

type Store = {
  indexPhase: number;
  setIndexPhase: (phase: number) => void;
  nextPhase: () => void;
  resetPhase: () => void;
  backPhase: () => void;
};

const usePhaseStore = create<Store>((set, get) => {
  // Get initial value from localStorage or fallback to 0
  const initialPhase = 0;

  return {
    indexPhase: initialPhase,
    setIndexPhase: (phase) => {
      // Update the phase and store it in localStorage
      localStorage.setItem('index-phase', phase.toString());
      set({ indexPhase: phase });
    },
    nextPhase: () => {
      set((state) => {
        const nextPhase = state.indexPhase + 1;
        localStorage.setItem('index-phase', nextPhase.toString());
        return { indexPhase: nextPhase };
      });
    },
    resetPhase: () => {
      localStorage.removeItem('index-phase');
      set({ indexPhase: 0 });
    },
    backPhase: () => {
      set((state) => {
        const prevPhase = state.indexPhase - 1;
        localStorage.setItem('index-phase', prevPhase.toString());
        return { indexPhase: prevPhase };
      });
    },
  };
});

export default usePhaseStore;
