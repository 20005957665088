import { FC, useCallback, useState } from 'react';

import NotFound from 'components/core/NotFound';
import View from 'components/core/View/View.view';
import FormikInputNumber from 'components/form/FormikInputNumber';
import ImagePicker from 'components/modules/ImagePicker';
import { ImageUploadHelper } from 'components/modules/ImagePicker/helpers/imageUploadHelper';
import UserLocationForm from 'components/modules/UserLocationForm';
import Button from 'components/primitives/Button';
import CheckBox from 'components/primitives/CheckBox';
import Loading from 'components/primitives/Loading';
import SelectInput from 'components/primitives/SelectInput';
import SelectOption from 'components/primitives/SelectOption';
import TextAreaInput from 'components/primitives/TextAreaInput';
import TextInput from 'components/primitives/TextInput';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import AddInludeInputBuilder from '../CreateItem/IncludesInputBuilder.view';
import { deliveryOptions, validationSchema } from '../CreateItem/utils';
import { EditItemViewProps } from './EditItem.props';
import { generateInitialDataFrom } from './utils';

const EditItemView: FC<EditItemViewProps> = ({
  onCancel,
  categories,
  onChangeCategory,
  subCategories,
  onSubmit,
  creationInProgress,
  itemQuery,
}) => {
  const item = itemQuery?.data;

  const [images, setImages] = useState<(string | File)[]>([]);
  const [featuredImage, setFeaturedImage] = useState<string>('');

  const onChange = useCallback(
    (images: (string | File)[], featuredImage: string) => {
      setImages(images);
      setFeaturedImage(featuredImage);
    },
    [],
  );

  if (!item && itemQuery?.isFetched) {
    return <NotFound description="Product not found!" hideButton />;
  }

  const featuredImageTemp = item?.featuredPhoto ? [item?.featuredPhoto] : [];
  const initialPhotosData = [...(item?.photos ?? []), ...featuredImageTemp];

  return (
    <View.Container>
      <div className="flex flex-col w-full justify-center ">
        {itemQuery?.isLoading ? (
          <Loading />
        ) : (
          <>
            <h1 className="items-center justify-center text-center">
              Edit Product
            </h1>
            <Formik
              initialValues={generateInitialDataFrom(item!)}
              onSubmit={(values, { setFieldError }) => {
                let data = { ...values } as any;
                data['featuredPhoto'] = featuredImage;
                data['photos'] = images;

                const imageUploadHelper = new ImageUploadHelper(
                  images,
                  featuredImage,
                );

                const isValid = imageUploadHelper.validate((message) => {
                  setFieldError('photos', message);
                });

                if (!isValid) return;

                onSubmit && onSubmit(data);
              }}
              validationSchema={validationSchema}>
              {(values: any) => {
                return (
                  <Form>
                    <div className="lg:flex justify-between gap-12 mt-8">
                      <div className="w-full grid grid-col-2 gap-x-6">
                        <div className="col-span-2">
                          <TextInput
                            key="title"
                            name="title"
                            placeholder="Example: Powerhorse Electric Pressure Washer — 3000 PSI 2.0 GPM Model 113880"
                            label="Title"
                            isRequired
                          />
                        </div>
                        <div>
                          <SelectInput
                            key="category"
                            disabled={categories.length == 0 ? true : false}
                            name="category"
                            onChange={onChangeCategory}
                            placeholder="Select category..."
                            label="Category"
                            tooltipMessage="Select the category of your item, could be “tools” “outdoors”, etc."
                            isRequired>
                            {categories.length > 0 && (
                              <option value="">Select Category</option>
                            )}
                            {categories.map((category) => (
                              <SelectOption
                                value={category._id}
                                child={category.name}
                              />
                            ))}
                          </SelectInput>
                        </div>
                        <div>
                          <SelectInput
                            key="subcategory"
                            disabled={subCategories.length == 0 ? true : false}
                            name="subCategory"
                            placeholder="Select sub-category..."
                            label="Sub-Category"
                            tooltipMessage="The subcategory is referring to.."
                            isRequired>
                            {subCategories.length > 0 && (
                              <option>Select Sub Category</option>
                            )}
                            {subCategories.map((category, index) => (
                              <SelectOption
                                key={category._id + index}
                                value={category._id}
                                child={category.name}
                              />
                            ))}
                          </SelectInput>
                        </div>
                        <div className="w-full">
                          <TextInput
                            key="modelNumber"
                            name="modelNumber"
                            placeholder="Enter model number..."
                            label="Model Number"
                            isRequired
                            tooltipMessage="The model or model number of your item"
                          />
                        </div>
                        <div className="w-full">
                          <TextInput
                            name="serialNumber"
                            key="serialNumber"
                            placeholder="Enter serial number or barcode..."
                            label="Serial Number Or Barcode"
                            tooltipMessage="You can find this number on the item or on the box"
                          />
                        </div>
                        <div className="col-span-2">
                          <UserLocationForm formModeOff cityName="location" />
                        </div>

                        <div className="col-span-2">
                          <TextAreaInput
                            isRequired
                            key="description"
                            name="description"
                            label="Description"
                            placeholder="Provide key specs..."
                          />
                        </div>
                        <div>
                          <FormikInputNumber
                            key="rentalPrice"
                            placeholder="Enter rental price..."
                            label="Rental Price (Per Day)"
                            name="rentalPrice"
                            min={5}
                            max={250}
                            tooltipMessage={`The recommended price per day is around 10% of the item's retail price.`}
                          />
                        </div>
                        <div>
                          <AddInludeInputBuilder />
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="col-span-2">
                          <ImagePicker
                            isRequired
                            toolTipMessage="After uploading please select a featured image"
                            featuredImageInitialValue={
                              item?.featuredPhoto ?? ''
                            }
                            limit={5}
                            initialData={initialPhotosData ?? []}
                            onChange={onChange}
                            onLoad={onChange}
                          />

                          <ErrorMessage
                            component="div"
                            className="text-red-500 text-sm mt-2"
                            name={'photos'}
                          />
                        </div>

                        <div className="mt-[14px]">
                          <TextAreaInput
                            key="guidelines"
                            name="guidelines"
                            label="Guidelines"
                            placeholder="Example: Item includes a wood cutting blade. Not intended for cutting metal or plastics. Not available on Sundays"
                          />
                        </div>
                        <div>
                          <TextInput
                            key="ownersManual"
                            name="ownersManual"
                            placeholder="Enter owner’s manual link..."
                            label="Link to owner’s manual"
                            tooltipMessage="The manufacturer manual or any manual that helps the renter to use the item"
                          />
                        </div>
                        <div className="w-[50%]">
                          <CheckBox
                            key="deliveryOptions"
                            name="deliveryOptions"
                            label="Pickup/Delivery Preferences"
                            isRequired>
                            {deliveryOptions.map((option) => {
                              return (
                                <div key={option.name}>
                                  <div className="flex flex-row gap-1 items-center border-primary-500">
                                    <Field type="checkbox" name={option.name}>
                                      {({ field }: FieldProps) => {
                                        return (
                                          <input
                                            {...field}
                                            type="checkbox"
                                            className="checkbox"
                                          />
                                        );
                                      }}
                                    </Field>
                                    {option.label}
                                  </div>
                                </div>
                              );
                            })}
                          </CheckBox>
                        </div>
                      </div>
                    </div>

                    <div className="px-10 flex flex-col gap-10 mt-4">
                      <div className="flex md:flex-row flex-col justify-center md:gap-10 gap-4">
                        <Button
                          id="open-btn"
                          type="button"
                          onClick={onCancel}
                          variant={'outline'}
                          colorScheme={'secondary'}
                          size="w-64">
                          Cancel
                        </Button>
                        <Button
                          id="open-btn"
                          type="submit"
                          isLoading={creationInProgress}
                          variant={'solid'}
                          colorScheme={'primary'}
                          size="w-64">
                          Update Item
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </View.Container>
  );
};
export default EditItemView;
