import { forwardRef } from 'react';

import CheckIcon from 'components/icons/CheckIcon';
import CommonModal from '../CommonModal';
import { CommonModalRef } from '../CommonModal/CommonModal.props';
import { SuccessModalProps } from './SuccessModal.props';

const SuccessModalView = forwardRef<CommonModalRef, SuccessModalProps>(
  (props, ref) => {
    return (
      <CommonModal
        ref={ref}
        autoClose={true}
        isCloseButton={false}
        onSuccessFullyClosed={() => props.onClose && props.onClose()}
        isDefaultOpen={props.isOpen ?? false}
        className="w-[23.5rem] h-[22.125rem] md:w-[43.5rem] md:h-[22.125rem] flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4">
          <CheckIcon />
          <div className="text-center text-gray-700 text-3xl font-semibold font-primary capitalize">
            {props.title ?? 'Success!'}
          </div>
          {props.description && (
            <p className="text-lg px-20 text-[#737373]">{props.description}</p>
          )}
        </div>
      </CommonModal>
    );
  },
);

export default SuccessModalView;
