import { QUERY_KEYS } from 'api/queries';
import ArrowDropdownIcon from 'components/icons/ArrowDropdown';
import { routesSettings } from 'constants/routes';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { $session } from 'state/stores';
import { $itemRentDetails } from 'state/stores/rent-details';
import { $stripe } from 'state/stores/stripe';
import { $user } from 'state/stores/user';
import { getNetworkImage } from 'utils/assets';
import { MenuHeaderViewProps } from './MenuHeader.props';

const MenuHeaderView: FC<MenuHeaderViewProps> = ({ fullName, userProfile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement | null>(null); // Allow null as initial value

  // Close the dropdown when query params change
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);

  // Close the dropdown when ref changes

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    $session.actions.clearSession();
    $user.actions.clearUserData();
    $stripe.actions.reset();
    $itemRentDetails.actions.reset();
    await QUERY_KEYS.invalidateAll();
    navigate(routesSettings.AUTH_LOGIN.path);
    setIsDropdownOpen(false);
  };

  const handleProfile = () => {
    navigate(routesSettings.MAIN_PROFILE.path);
    setIsDropdownOpen(false);
  };

  const navigateToHistory = () => {
    navigate(routesSettings.LEND.path, {
      state: {
        activeTab: 2,
      },
    });
    setIsDropdownOpen(false);
  };

  return (
    <div
      className="dropdown dropdown-bottom rounded w-full"
      onClick={toggleDropdown}>
      <div tabIndex={0} role="button" className="btn m-1  rounded">
        <div tabIndex={0} role="button" className="avatar mr-2">
          {userProfile ? (
            <div className="w-10 rounded-lg">
              <img src={getNetworkImage(userProfile.avatar)} />
            </div>
          ) : (
            <div className="w-10 rounded-lg bg-slate-400"></div>
          )}
        </div>
        <h5 className="capitalize">{fullName}</h5>{' '}
        <ArrowDropdownIcon isDropdownOpen={isDropdownOpen} />
      </div>
      <ul
        tabIndex={0}
        ref={dropdownRef}
        style={{
          visibility: isDropdownOpen ? 'visible' : 'hidden',
        }}
        className="dropdown-content menu p-2 shadow top-[65%] bg-white  rounded-lg w-full">
        <li onClick={handleProfile}>
          <h5>Profile</h5>
        </li>
        <li onClick={navigateToHistory}>
          <h5>Activity</h5>
        </li>
        <li
          onClick={handleLogout}
          className="text-red-500 hover:bg-red-100 rounded-lg ">
          <h5 className="text-red-700 hover:text-red-500">Logout</h5>
        </li>
      </ul>
    </div>
  );
};

export default MenuHeaderView;
