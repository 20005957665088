import { FC, useEffect } from 'react';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import NotFound from 'components/core/NotFound';

import { ROUTES, routesSettings } from 'constants/routes';

// IMPORT ROUTE
import { useUser } from 'api/queries';
import Header from 'components/core/Header';
import StripeHandler from 'components/core/StripeHandler';
import MobileMenu from 'components/modules/MobileMenu';
import Loading from 'components/primitives/Loading';
import useIsMobile from 'hooks/useIsMobile';
import CreateItem from 'routes/main/CreateItem';
import EditItem from 'routes/main/EditItem';
import Home from 'routes/main/Home';
import ItemHistory from 'routes/main/ItemHistory';
import Lend from 'routes/main/Lend';
import OwnerProfile from 'routes/main/OwnerProfile';
import { Payment } from 'routes/main/Payment';
import PaymentSuccessfull from 'routes/main/PaymentSuccessfull';
import ProductQuestions from 'routes/main/ProductQuestions';
import Profile from 'routes/main/Profile';
import Rent from 'routes/main/Rent';
import RentDetails from 'routes/main/RentDetails';
import RentalProduct from 'routes/main/RentalProduct';
import TermsAndConditions from 'routes/main/TermsAndConditions';
import PublicRoutes from 'routes/public';
import { $session } from 'state/stores';
import { $user } from 'state/stores/user';
import { withMotion } from 'utils';
import PrivacyPolicy from './PrivacyPolicy';

const routeConfig = {
  // OWNER ROUTE ENTRY
  [ROUTES.RENT]: {
    component: Rent,
    path: routesSettings.RENT.path,
  },
  [ROUTES.LEND]: {
    component: Lend,
    path: routesSettings.LEND.path,
  },
  [ROUTES.HOME]: {
    component: Home,
    path: routesSettings.HOME.path,
  },
  [ROUTES.MAIN_RENT_PRODUCT]: {
    component: RentalProduct,
    path: routesSettings.MAIN_RENT_PRODUCT.path,
  },
  [ROUTES.MAIN_RENT_PRODUCT_RENT_DETAILS]: {
    component: RentDetails,
    path: routesSettings.MAIN_RENT_PRODUCT_RENT_DETAILS.path,
  },
  [ROUTES.MAIN_LEND_PRODUCT]: {
    component: RentalProduct,
    path: routesSettings.MAIN_LEND_PRODUCT.path,
  },
  [ROUTES.MAIN_LEND_PRODUCT_RENT_DETAILS]: {
    component: RentDetails,
    path: routesSettings.MAIN_LEND_PRODUCT_RENT_DETAILS.path,
  },
  [ROUTES.MAIN_PROFILE]: {
    component: Profile,
    path: routesSettings.MAIN_PROFILE.path,
  },
  [ROUTES.MAIN_PAYMENT]: {
    component: Payment,
    path: routesSettings.MAIN_PAYMENT.path,
  },

  [ROUTES.MAIN_PAYMENT_SUCCESSFULL]: {
    component: PaymentSuccessfull,
    path: routesSettings.MAIN_PAYMENT_SUCCESSFULL.path,
  },
  [ROUTES.MAIN_OWNER_PROFILE]: {
    component: OwnerProfile,
    path: routesSettings.MAIN_OWNER_PROFILE.path,
  },
  [ROUTES.MAIN_PRODUCT_QUESTIONS]: {
    component: ProductQuestions,
    path: routesSettings.MAIN_PRODUCT_QUESTIONS.path,
  },
  [ROUTES.MAIN_CREATE_ITEM]: {
    component: CreateItem,
    path: routesSettings.MAIN_CREATE_ITEM.path,
  },
  [ROUTES.MAIN_EDIT_ITEM]: {
    component: EditItem,
    path: routesSettings.MAIN_EDIT_ITEM.path,
  },
  [ROUTES.MAIN_ITEM_HISTORY]: {
    component: ItemHistory,
    path: routesSettings.MAIN_ITEM_HISTORY.path,
  },

  [ROUTES.MAIN_PRIVACY_POLICY]: {
    component: PrivacyPolicy,
    path: routesSettings.MAIN_PRIVACY_POLICY.path,
  },

  [ROUTES.MAIN_TERMS_AND_CONDITIONS]: {
    component: TermsAndConditions,
    path: routesSettings.MAIN_TERMS_AND_CONDITIONS.path,
  },

  // should be the last
  [ROUTES.NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.NOT_FOUND.path,
  },
} as const;

const MainRoutes: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userID = $session.actions.getUserId();
  const isMobile = useIsMobile();

  const { isLoading } = useUser(userID!, undefined, (userData) => {
    $user.actions.setUser(userData);
  });

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(routeConfig.HOME.path, {
        replace: true,
      });
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <StripeHandler>
          <Header />
          <Routes>
            {Object.values(routeConfig).map((route, index) => (
              <Route
                key={route.path + index}
                path={route.path}
                Component={withMotion(route.component) as FC}
              />
            ))}
            {PublicRoutes()}
          </Routes>
          {isMobile ? <MobileMenu /> : <></>}
        </StripeHandler>
      )}
    </>
  );
};

export default MainRoutes;
