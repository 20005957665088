import { useQuery } from '@tanstack/react-query';
import { getRentHistory } from 'api/services/rent';

const FETCH_RENT_HISTORY_KEY = 'fetch-rent-history';
const CACHE_TIME = 5 * 60 * 1000; //5 minutes

const useRentHistoryQuery = (
  userId: string,
  page?: number,
  fetchOwnerRentals?: boolean,
  statuses?: string,
) => {
  const query = useQuery({
    queryKey: [FETCH_RENT_HISTORY_KEY, page, fetchOwnerRentals],
    queryFn: () =>
      getRentHistory(userId, {
        page,
        owner: fetchOwnerRentals ?? false,
        statuses,
      }),
    enabled: !!userId,
    gcTime: CACHE_TIME,
  });

  return query;
};

export default useRentHistoryQuery;

export { FETCH_RENT_HISTORY_KEY };
