import { loadStripe } from '@stripe/stripe-js';
import { PaymentProps } from './Payment.props';

import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { useRentPaymentDetails } from 'api/queries';
import LoadingView from 'components/primitives/Loading';
import Timer from 'components/primitives/Timer/Timer.view';
import { useNavigate, useParams } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY || '');

const PaymentContainer = (props: PaymentProps) => {
  const { rentId } = useParams();
  const {
    data: rentPaymentDetails,
    isLoading,
    error,
    refetch,
  } = useRentPaymentDetails(rentId!);

  const navigate = useNavigate();

  if (isLoading) return <LoadingView />;

  const ErrorDisplay = () => (
    <div
      style={{
        backgroundColor: '#2ECC71',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '12px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          maxWidth: '400px',
        }}>
        <h2 style={{ color: '#2C3E50', marginBottom: '1rem' }}>
          Payment Session Expired
        </h2>
        <p style={{ color: '#7F8C8D', marginBottom: '1.5rem' }}>
          The payment session has expired or is invalid. Please try again.
        </p>
        <button
          onClick={() => navigate('/home')}
          style={{
            backgroundColor: '#2ECC71',
            color: 'white',
            border: 'none',
            padding: '0.75rem 1.5rem',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '1rem',
            transition: 'background-color 0.2s',
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = '#27AE60')
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = '#2ECC71')
          }>
          Go Home
        </button>
      </div>
    </div>
  );

  if (error) {
    return <ErrorDisplay />;
  }

  const clientSecret = rentPaymentDetails?.secret || null;
  const expiresAt = rentPaymentDetails?.expiresAt
    ? rentPaymentDetails?.expiresAt + 10
    : null;

  return (
    <div
      id="checkout"
      style={{
        backgroundColor: '#2ECC71',
        minHeight: '100vh',
      }}>
      <div style={{ paddingTop: '20px' }}>
        {expiresAt && (
          <Timer expiresAt={expiresAt} onExpire={() => refetch()} />
        )}
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          key={rentId}
          options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  );
};

export default PaymentContainer;
