import { IconProps } from './type';

const TwitterXIcon = (props: IconProps) => {
  return (
    <svg
      width={props.w || '30'}
      height={props.h || '30'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        d="M17.5 3H20.5L13.5 10.5L21.5 21H15.5L10.5 14.5L4.5 21H1.5L9 12L1 3H7L11.5 8.8L17.5 3ZM16.4 19H18.1L6.5 5H4.7L16.4 19Z"
        fill="#22cc7a"
      />
    </svg>
  );
};

export default TwitterXIcon;
