import { IconProps } from './type';

const FacebookIcon = (props: IconProps) => {
  return (
    <svg
      width={props.w ? props.w : '30'}
      height={props.h ? props.h : '30'}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.5559 0H4.44535C2.00094 0 0.000976562 1.99997 0.000976562 4.44437V35.555C0.000976562 38.0016 2.00094 39.9993 4.44535 39.9993H20.0006V24.444H15.5563V18.9441H20.0006V14.3886C20.0006 9.57984 22.6939 6.20212 28.3694 6.20212L32.376 6.20656V11.9954H29.716C27.5072 11.9954 26.6672 13.6531 26.6672 15.1909V18.9463H32.3738L31.1116 24.444H26.6672V39.9993H35.5559C38.0003 39.9993 40.0003 38.0016 40.0003 35.555V4.44437C40.0003 1.99997 38.0003 0 35.5559 0Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
