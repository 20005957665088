import { FC } from 'react';

import HorizontalElipses from 'components/icons/HorizontalElipses';
import { ThreeDotsProps } from './ThreeDots.props';

const ThreeDotsView: FC<ThreeDotsProps> = (props) => {
  const { children, classname, tabIndex, role } = props;
  return (
    <div className="dropdown dropdown-left px-2 m-0 z-20">
      <div tabIndex={0} role="button" className="">
        <HorizontalElipses fill="grey" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content  z-[1] menu p-2 shadow bg-base-100 rounded-box align-middle items-start">
        {children}
      </ul>
    </div>
  );
};

export default ThreeDotsView;
