import { HistoryResponse } from 'types/history';
import {
  OnGoingRentResponse,
  OnGoingRentResponseList,
  RentCreationResponse,
  RentPayload,
  RentPaymentDetailResponse,
  RentStatusResponse,
  TopRentsResponse,
} from 'types/rent';
import { api } from './instance';

export const getRentAvailabilty = async (
  itemId: string,
  startDate: string,
  endDate: string,
) => {
  const { data } = await api.get<RentStatusResponse>(`/rent`, {
    params: {
      itemId,
      startDate,
      endDate,
    },
  });
  return data?.item;
};

export const rentItem = async (payload: RentPayload) => {
  const { data } = await api.post<RentCreationResponse>(`/rent`, payload);
  return data?.item;
};

export const getOngoingRents = async (itemId: string, date: string) => {
  const { data } = await api.get<OnGoingRentResponseList>(
    `/rent/get-ongoing-rents`,
    {
      params: {
        itemId,
        date,
      },
    },
  );
  return data?.item;
};

export const getRentDetails = async (itemId: string, date: string) => {
  const { data } = await api.get<OnGoingRentResponse>(
    `/rent/details/renter/item-id/${itemId}`,
    {
      params: {
        date,
      },
    },
  );
  return data?.item ?? null;
};

export const getRentDetailsByRentId = async (rentId: string) => {
  const { data } = await api.get<OnGoingRentResponse>(
    `/rent/details/${rentId}`,
  );
  return data?.item;
};

export const getRentPaymentDetails = async (rentId: string) => {
  const { data } = await api.post<RentPaymentDetailResponse>(
    `/rent/stripe/payment/${rentId}`,
  );
  return data?.item;
};

export const getTopRents = async () => {
  const { data } = await api.get<TopRentsResponse>(`/rent/topRents`);
  return data?.item;
};

interface RentHistoryRequestOptions {
  limit?: number;
  page?: number;
  sort?: number;
  owner?: boolean;
  statuses?: string;
}

const defaultOptions: RentHistoryRequestOptions = {
  limit: 10,
  page: 1,
  sort: 1,
  owner: true,
};

export const getRentHistory = async (
  userId: string,
  options?: RentHistoryRequestOptions,
) => {
  const requestOptions = { ...defaultOptions, ...options };
  const { data } = await api.get<HistoryResponse>(`/rent/history`, {
    params: { userId, ...requestOptions, page: options?.page ?? null },
  });
  return data;
};
