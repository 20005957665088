import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import DeleteIcon from 'components/icons/DeleteIcon';
import UploadIcon from 'components/icons/UploadIcon';
import AlertModal from 'components/modals/AlertModal';
import ImageHelper from 'helperClasses/ImageHelper';
import ModalReference from 'helperClasses/modalReference';
import { toast } from 'react-toastify';
import { getNetworkImage } from 'utils/assets';
import ToastHelper from 'utils/toast';
import Label from '../../primitives/Label';
import { ImagePickerProps } from './ImagePicker.props';

export type ImageProps = {
  index: number;
  file: File | string;
  isSelected?: boolean;
  onClick?: () => void;
  onDelete?: (index: number) => void;
};

const ImagePickerView: FC<ImagePickerProps> = React.memo(
  ({
    beforeDeleteCallback,
    initialData = [],
    hint = '',
    onChange,
    featuredImageInitialValue = '',
    onLoad,
    isRequired = false,
    toolTipMessage,
    limit = 10,
  }) => {
    const [images, setImages] = useState<(string | File)[]>(initialData);
    const [featuredImage, setFeaturedImage] = useState<string>(
      featuredImageInitialValue,
    );
    const deleteAlertModalRef = new ModalReference();
    const inputRef = useRef<HTMLInputElement>(null);
    const imagesSelected = images.length > 0;
    const [clickedImageIndex, setClickIndex] = useState<number | undefined>();

    const [functionExecuted, setFunctionExecuted] = useState(false);
    useEffect(() => {
      if (
        onLoad &&
        images.length > 0 &&
        featuredImage !== '' &&
        !functionExecuted
      ) {
        // Run your function here
        onLoad && onLoad(images, featuredImage);
        // Set functionExecuted to true to prevent the function from running again
        setFunctionExecuted(true);
      }
    }, [images, featuredImage, functionExecuted]);

    const pickImages = useCallback(() => {
      inputRef?.current?.click();
    }, []);

    const onClickFeaturedImage = (filename: string) => {
      setFeaturedImage(filename);
      onChange && onChange(images, filename);
    };

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const fileList = event.target.files;
      if (fileList?.length === 0) {
        return;
      }

      const fileCount = new Map();
      const prevFiles: (File | string)[] = images;
      const newFiles: File[] = [];

      let foundDuplicates = false;

      for (let i = 0; i < prevFiles.length; i++) {
        const file = prevFiles[i];
        const key = typeof file == 'string' ? file : file.name + file.size;
        fileCount.set(key, (fileCount.get(key) || 0) + 1);
      }

      const compressionPromise = new Promise(async (resolve, reject) => {
        try {
          setTimeout(async () => {
            for (let i = 0; i < fileList!.length; i++) {
              const file = await ImageHelper.compress(fileList![i] as File);
              const key = file.name + file.size;
              if (fileCount.has(key)) {
                foundDuplicates = true;
              } else {
                newFiles.push(file);
              }
            }
            resolve(true);
          }, 1000);
        } catch (error) {
          reject(error);
        }
      });

      await toast.promise(compressionPromise, {
        pending:
          fileList!.length > 1 ? 'Processing Images' : 'Processing Image',
      });

      if (foundDuplicates) {
        ToastHelper.error('Some files are already added.');
      }

      setImages((prev) => {
        const allPhotos = [...prev, ...newFiles];
        let extraImagesCount = allPhotos.length - limit;
        if (extraImagesCount > 0) {
          allPhotos.splice(0, extraImagesCount);
        }
        if (allPhotos.length == limit) {
          ToastHelper.error(`Maximum image upload limit reached`);
        }
        if (onChange) {
          onChange(allPhotos, featuredImage);
        }
        return allPhotos;
      });
    };

    const deleteImage = (index: number) => {
      setClickIndex(index);
      deleteAlertModalRef.open();
    };

    const handleDelete = async () => {
      deleteAlertModalRef.close();
      const currentPhotos = images;
      const image = currentPhotos[clickedImageIndex!];
      //handle featured image
      const isNetworkImage = (image: string | File) =>
        typeof image === 'string';

      let fileName;
      if (typeof image !== 'string') {
        fileName = image.name;
      } else {
        fileName = image;
      }

      const isFeaturedImage = featuredImage === fileName;

      if (isFeaturedImage) {
        setFeaturedImage('');
      }

      if (isNetworkImage(image)) {
        if (beforeDeleteCallback) {
          const photos: string[] = currentPhotos
            .filter(
              (_image: any, index: number) =>
                isNetworkImage(_image) && index !== clickedImageIndex!,
            )
            .map((_image: any) => _image as unknown as string);

          await beforeDeleteCallback(photos, featuredImage ?? '');
        }
      }

      const updatedImage = currentPhotos.filter(
        (_image: any, index: number) => index !== clickedImageIndex!,
      );

      setImages(updatedImage);

      onChange && onChange(updatedImage, isFeaturedImage ? '' : featuredImage);
    };

    const ImagePicker = () => {
      return (
        <div
          style={{
            height: '100px',
            width: '100px',
          }}
          className="w-full flex-col h-14  bg-purple-100 rounded-lg border border-secondary-900 border-dashed flex justify-center items-center cursor-pointer"
          onClick={() => pickImages()}>
          <UploadIcon fill={'#682082'} />
          <div className="text-secondary-900 text-center text-xs font-semibold font-primary pt-1">
            Upload your pictures
          </div>
        </div>
      );
    };

    const Image: FC<ImageProps> = React.memo(
      ({ file, isSelected = false, onClick, onDelete, index }) => {
        return (
          <div className="relative">
            <img
              onClick={() => onClick && onClick()}
              height="100px"
              width="100px"
              style={{
                border: isSelected ? '4px solid #29ac6d' : '',
              }}
              className="aspect-square object-cover  rounded-lg cursor-pointer"
              src={
                typeof file == 'string'
                  ? getNetworkImage(file)
                  : URL.createObjectURL(file)
              }
            />
            <button
              type="button"
              className="absolute top-2 right-2"
              onClick={() => onDelete && onDelete(index)}>
              <DeleteIcon h="30px" w="30px" />
            </button>
          </div>
        );
      },
    );

    const ImageRenderer = React.memo(() => {
      return (
        <div className="w-full flex flex-wrap gap-4">
          {images.length < limit && <ImagePicker />}
          {images.map((file, index) => {
            const fileName = typeof file === 'string' ? file : file?.name ?? '';
            return (
              <Image
                key={index}
                onDelete={deleteImage}
                index={index}
                isSelected={featuredImage === fileName}
                file={file}
                onClick={() => onClickFeaturedImage(fileName)}
              />
            );
          })}
        </div>
      );
    });

    return (
      <React.Fragment>
        <Label
          name={'dropzone-file'}
          toolTipMessage={toolTipMessage ?? ''}
          isRequired={isRequired}
          title={'Photos'}
        />
        <input
          id="dropzone-file"
          type="file"
          key="files"
          ref={inputRef}
          className="hidden"
          multiple
          accept=".png, .jpg, .jpeg, .heic, .raw, .heif"
          onChange={(e) => handleFileChange(e)}
        />

        {!imagesSelected ? (
          <div className="w-full  flex justify-center items-start bg-purple-100 rounded-lg border border-secondary-900 border-dashed">
            <div
              onClick={pickImages}
              className="flex flex-col justify-center items-center gap-2 py-20 cursor-pointer">
              <UploadIcon fill={'#682082'} />
              <div className="text-secondary-900 text-base font-semibold font-primary">
                Upload your pictures (min 3)
              </div>
              <span className="text-center text-xs">{hint}</span>
            </div>
          </div>
        ) : (
          <ImageRenderer />
        )}

        <AlertModal
          ref={deleteAlertModalRef.getReference()}
          title="Delete Image"
          buttonType="button"
          description="Are you sure you want to delete this image?"
          onClickButton={handleDelete}
          buttonTitle="Yes"
        />
      </React.Fragment>
    );
  },
);

export default ImagePickerView;
