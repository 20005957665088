import { FC } from 'react';

import images from 'assets/images';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import ErrorIcon from 'components/icons/ErrorIcon';
import CommonContainer from 'components/primitives/CommonContainer';
import Loading from 'components/primitives/Loading';
import { ProfileStripeSectionViewProps } from './ProfileStripeSection.props';

const ProfileStripeSectionView: FC<ProfileStripeSectionViewProps> = ({
  isFetching,
  isConnected,
  onClickChangeInformation,
  onClickConnect,
  isAccountEnabled,
}) => {
  if (isFetching) {
    return <Loading />;
  }

  // Define status configurations
  const stripeStatusConfig = {
    incomplete: {
      title: 'Profile Incomplete',
      description:
        'Please update your profile to be able to list items and receive payments',
      icon: <ErrorIcon />,
      button: (
        <Button
          onClick={onClickChangeInformation}
          className="btn btn-primary text-white capitalize font-bold">
          Update
          <img width={60} src={images.stripeLogoWhite} alt="" />
          Profile
        </Button>
      ),
    },
    connected: {
      title: 'Stripe Connect',
      description:
        'Stripe is connected. Click the button to update your payment information or manage your account.',
      icon: <CheckCircleIcon />,
      button: (
        <Button
          onClick={onClickChangeInformation}
          className="btn btn-primary text-white capitalize font-bold">
          Go to
          <img width={60} src={images.stripeLogoWhite} alt="" />
          Dashboard
        </Button>
      ),
    },
    notConnected: {
      title: 'Stripe Connect',
      description:
        'Looking to earn extra $$? Connect and verify your identity with Stripe to be able to list items and receive payments',
      icon: <ErrorIcon />,
      button: (
        <Button
          onClick={onClickConnect}
          className="btn btn-var-stripe capitalize">
          Connect to
          <img width={60} src={images.stripeLogoWhite} alt="" />
          Account
        </Button>
      ),
    },
  };

  // Determine current status
  const currentStatus = isConnected
    ? isAccountEnabled
      ? 'connected'
      : 'incomplete'
    : 'notConnected';

  const { title, description, icon, button } =
    stripeStatusConfig[currentStatus];

  return (
    <CommonContainer>
      <StripeStatus
        title={title}
        description={description}
        icon={icon}
        button={button}
      />
    </CommonContainer>
  );
};

type StripeStatusProps = {
  title: string;
  description: string;
  button: React.ReactNode;
  icon: React.ReactNode;
};

const StripeStatus: FC<StripeStatusProps> = ({
  title,
  description,
  icon,
  button,
}) => (
  <div className="w-full flex flex-col gap-8 md:flex-row justify-between">
    <div className="w-full flex flex-col gap-6 mb-2">
      <div className="flex items-center gap-4 mb-2">
        <h1>{title}</h1>
        {icon}
      </div>
      <span>{description}</span>
    </div>
    {button}
  </div>
);

type ButtonProps = {
  onClick: () => void;
  className: string;
  children?: React.ReactNode;
};

const Button: FC<ButtonProps> = ({ onClick, className, children }) => (
  <button onClick={onClick} className={className}>
    {children}
  </button>
);

export default ProfileStripeSectionView;
