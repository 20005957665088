import { FC, useState } from 'react';

import { HomeViewProps } from './Home.props';

import homeImg from 'assets/images/home_new_image.jpeg';

import secondSectionImg from 'assets/images/cards_woman.webp';
import thirdSectionImg from 'assets/images/desktop_work.webp';
import IntroModal from 'components/modals/IntroModal';
import useIsMobile from 'hooks/useIsMobile';
import { cardsSectionTitles } from 'utils/mockData';

import whiteLogo from 'assets/images/logo_white.png';
import Button from 'components/primitives/Button';

import { useItems } from 'api/queries';
import CategorySection from 'components/modules/CategorySection';
import MobileMenuHeader from 'components/modules/MobileMenuHeader';
import TopItemSection from 'components/modules/TopItemSection';
import Card from 'components/primitives/Card';
import Footer from 'components/primitives/Footer';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

type View = 'month' | 'century' | 'decade' | 'year';

const CardsSection = ({ section, cardsArray = [] }: any) => {
  const initialCardsToShow = 3;
  const isMobile = useIsMobile();

  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow);

  const handleSeeAllClick = (allCards: any[]) => {
    if (cardsToShow === allCards.length) {
      setCardsToShow(initialCardsToShow);
    } else {
      setCardsToShow(allCards.length); // Show all cards
    }
  };

  const cardsTobeShown = (cards: any[]) => {
    return isMobile ? cards.slice(0, cardsToShow) : cards;
  };

  return (
    <div className="min-h-min w-full px-9 my-10 md:px-24 grid grid-flow-row place-items-center pb-16">
      <div className="w-fit">
        <div className="flex-between">
          <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
            {cardsSectionTitles[section]}
          </p>
          {isMobile && (
            <button onClick={() => handleSeeAllClick(cardsArray)}>
              <h5 className="text-right text-green-900 font-normal font-secondary">
                See All
              </h5>
            </button>
          )}
        </div>

        <div>
          <div className="flex flex-row justify-center gap-10">
            {cardsTobeShown(cardsArray)?.map((item, index) => (
              <Card
                key={`${item.title}-${index}`}
                img={item.image}
                title={item.title}
                description={item.description}
                isMobile={isMobile}
                onCardClick={() => console.log('implement on click funciton')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeView: FC<HomeViewProps> = () => {
  const isMobile = useIsMobile();
  const products = useItems();
  const navigate = useNavigate();

  const onCreateProduct = () => {
    return navigate(routesSettings.LEND.path, {
      replace: true,
    });
  };

  return (
    <>
      <IntroModal />
      <div className="h-full">
        <section>
          <div
            className="home-header-img"
            style={{
              backgroundImage: `url(${homeImg})`,
            }}>
            <div className="bacground-overlay-home">
              {isMobile ? (
                <div className="flex w-full justify-between px-9 py-4">
                  <div className="w-[147px] h-7">
                    <img src={whiteLogo} />
                  </div>
                  <div>
                    <MobileMenuHeader />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <CategorySection />
        </section>

        <section>
          <div className="home-section-styles">
            <div className="w-36 md:w-80">
              <img src={secondSectionImg} alt="Woman with cards" />
            </div>
            <div className="flex flex-col w-64 gap-2 md:w-1/2 ">
              <p className="section-paragraph">
                Start Listing Items and Earn Extra Money!{' '}
              </p>
              <Button
                variant="outline"
                colorScheme="primary"
                className="w-32 md:w-64"
                onClick={onCreateProduct}>
                Create Item
              </Button>
            </div>
          </div>
        </section>
        <section>
          <TopItemSection />
        </section>
        <section>
          <div className="home-section-styles pb-16">
            <div className="flex flex-col w-40 gap-2 md:w-1/2 ">
              <p className="section-paragraph">
                We are working hard to keep our users safe!
              </p>
              {/* <Button variant="outline" colorScheme="primary" className="w-52">
                I want to know how
              </Button> */}
            </div>

            <div className="w-36 md:w-80">
              <img src={thirdSectionImg} alt="Woman with cards" />
            </div>
          </div>
        </section>
        {isMobile ? <></> : <Footer />}
      </div>
    </>
  );
};

export default HomeView;
