import { FC } from 'react';

import Footer from 'components/primitives/Footer';
import useIsMobile from 'hooks/useIsMobile';
import { TermsAndConditionsViewProps } from './TermsAndConditions.props';

const TermsAndConditionsView: FC<TermsAndConditionsViewProps> = (props) => {
  const isMobile = useIsMobile();
  return (
    <div>
      <iframe
        style={{
          width: '100vw',
          height: '100vh',
        }}
        src="/terms.html"></iframe>
      {isMobile ? <></> : <Footer />}
    </div>
  );
};

export default TermsAndConditionsView;
