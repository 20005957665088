import UserLocationForm from 'components/modules/UserLocationForm';
import usePhaseStore from 'state/atoms/useIndexPhase';

const UserCitySelection = () => {
  const { nextPhase, indexPhase } = usePhaseStore();
  return (
    <div className="">
      <h4 className="text-center text-[18px] my-4">Select your city</h4>
      <div className="w-full">
        <UserLocationForm hideLabels onSuccess={nextPhase} />
      </div>
      <p className="text-center px-8 text-[#848484] text-[14px] md:w-96">
        Initial launch is limited to Minnesota. We're excited to expand toss
        additional markets in the near future. Please follow for updates.
      </p>
    </div>
  );
};

export default UserCitySelection;
