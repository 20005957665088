import { FC, useEffect, useState } from 'react';

import { UserLocationFormProps } from './UserLocationForm.props';

import userProfileUpdateMutation from 'api/mutations/useUserProfileMutation';
import useAddressLocationQuery from 'api/queries/useLocationQuery';
import { $user } from 'state/stores/user';
import { City } from 'types/location';
import ToastHelper from 'utils/toast';
import UserLocationFormView from './UserLocationForm.view';

const UserLocationFormContainer: FC<UserLocationFormProps> = ({
  formModeOff,
  hideLabels = false,
  stateName,
  cityName,
  onSuccess,
}) => {
  const toast = ToastHelper;
  const user = $user.selectors.useUser();
  const userLocation = $user.selectors.useLocation();

  const { useStates, useCitiesById } = useAddressLocationQuery;
  const [stateId, setStateId] = useState<undefined | string>(
    userLocation.stateId,
  );

  const [cityId, setCityId] = useState<undefined | string>(userLocation.cityId);

  useEffect(() => {
    setStateId(userLocation.stateId);
  }, [userLocation.stateId]);

  const { data: states, isLoading: statesLoading } = useStates();
  const { data: cities, isLoading: citiesLoading } = useCitiesById(stateId);

  const { mutate, isPending } = userProfileUpdateMutation(user?.profileId!, {
    onSuccess: (response: any) => {
      const state = response.state;
      const address = response.address;
      const city: City = {
        _id: address,
        state: {
          _id: state,
        },
      };
      $user.actions.setAddress(city);
      onSuccess?.();
      toast.success('City updated successfully');
    },
  });

  return (
    <UserLocationFormView
      controller={{
        states: states ?? [],
        cities: cities ?? [],
        statesLoading: statesLoading,
        citiesLoading: citiesLoading,
        isLoading: isPending,
        selectedStateId: stateId,
        selectedCityId: cityId,
        onSubmit: async (values: any) => {
          mutate(values);
        },
        onChangeState: (stateId: string) => {
          setStateId(stateId);
        },
      }}
      formModeOff={formModeOff}
      hideLabels={hideLabels}
      stateName={stateName}
      cityName={cityName}
    />
  );
};

export default UserLocationFormContainer;
