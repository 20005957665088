import useConnectToStripeMutation from 'api/mutations/useConnectToStripeMutation';
import Wallet from 'components/icons/Wallet';
import Button from 'components/primitives/Button';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import usePhaseStore from 'state/atoms/useIndexPhase';
import { $session } from 'state/stores/session';
import { $user } from 'state/stores/user';

const StripeConnectivity = () => {
  const navigate = useNavigate();
  const stripeId = $user.selectors.useStripeId();
  const isAddressSet = $user.selectors.useIsAddressSet();
  const { resetPhase } = usePhaseStore();

  const { mutate: connectStripeAccount } = useConnectToStripeMutation(
    stripeId!,
    window.location.origin + '/home?fromStripe=true&onboarding=true',
  );

  const doItLater = (): void => {
    $session.actions.setAuth();
    navigate(`${routesSettings.HOME.path}?onboarding=true`, {
      replace: true,
    });
    resetPhase();
  };
  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="flex flex-col items-center">
        <Wallet />
        <h2 className="my-2">Connect to stripe</h2>
        <p className="text-center font-secondary mt-1 md:w-96">
          To become an owner and rent your items you need to be connected to
          Stripe. This ensures smooth and easy transactions when receiving and
          sending payments.
        </p>
      </div>
      <div className="flex flex-col w-full">
        <button
          disabled={!isAddressSet}
          onClick={() => {
            resetPhase();
            connectStripeAccount();
          }}
          className="btn btn-var-stripe capitalize w-full md:w-96">
          Connect Stripe Account
        </button>
        <Button
          disabled={!isAddressSet}
          onClick={doItLater}
          variant="outline"
          colorScheme="secondary"
          className="mt-5 w-full md:w-96">
          I Will Do It Later
        </Button>
      </div>
    </div>
  );
};

export default StripeConnectivity;
