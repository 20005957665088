import { FC, useState } from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { TabsProps } from './Tabs.props';

const TabsView: FC<TabsProps> = ({ views, headings, initialTab = 0 }) => {
  const [currentTabIndex, setCurrentIndex] = useState(initialTab);
  const isMobile = useIsMobile();
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex justify-center">
        <div
          role="tablist"
          className={
            isMobile
              ? 'flex justify-start mt-7 text-sm font-primary whitespace-nowrap'
              : 'tabs flex md:justify-between mt-7 text-sm font-primary'
          }>
          {headings.map((heading, index) => {
            return (
              <a
                key={index + heading}
                role="tab"
                className={`tab ${
                  currentTabIndex === index
                    ? `text-primary-500`
                    : `text-gray-500`
                }`}
                onClick={() => {
                  setCurrentIndex(index);
                  // clearSearchParams();
                  // setOpenTab(Tab.ALL_ITEMS);
                }}>
                {heading}
              </a>
            );
          })}
        </div>
      </div>
      <div className="w-full">{views[currentTabIndex]}</div>
    </div>
  );
};

export default TabsView;
