import React from 'react';

import { cn } from 'utils/tailwindHelpers';

import { ButtonProps } from './Button.props';

const ButtonView = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      size,
      variant,
      children,
      className,
      isLoading,
      type = 'submit',
      isDisabled,
      colorScheme = 'primary',
      ...defaultProps
    } = props;

    const btnDisabled = isDisabled ? 'btn-disabled' : '';
    const btnColoscheme =
      colorScheme === 'primary' ? 'btn-primary' : 'btn-secondary';
    const btnVariant =
      variant === 'solid' ? 'btn text-white' : 'btn btn-outline border-2';
    const btnBg = variant === 'outline' ? 'bg-white' : '';
    const btnSize = size ? `${size} h-auto` : '';

    return (
      <button
        type={type}
        disabled={isLoading}
        ref={ref}
        style={{
          height: '30px !important',
        }}
        className={cn(
          'capitalize',
          'font-primary',
          btnDisabled,
          btnColoscheme,
          btnVariant,
          btnBg,
          btnSize,
          className,
        )}
        {...defaultProps}>
        {isLoading ? (
          <span className="loading loading-spinner"></span>
        ) : (
          children
        )}
      </button>
    );
  },
);

export default ButtonView;
