import { FC } from 'react';

import copyRight from 'assets/images/copyright.png';
import whiteLogo from 'assets/images/logo_white.png';
import FacebookIcon from 'components/icons/FacebookIcon';
import InstagremIcon from 'components/icons/InstagramIcon';
import LinkedInIcon from 'components/icons/LinkedInIcon';
import TwitterXIcon from 'components/icons/TwitterIcon';
import { routesSettings } from 'constants/routes';
import currentYear from 'utils/getCurrentYear';
import { FooterProps } from './Footer.props';

const FooterView: FC<FooterProps> = (props) => {
  return (
    <footer className="footer row p-10 bg-primary-500 text-white justify-between items-end px-24">
      <div className="flex flex-col">
        <div className="flex flex-col justify-end items-end">
          <img src={copyRight} />
          <img src={whiteLogo} sizes="1rem" />
        </div>
        <p className="color-white">Copyright {currentYear}</p>
      </div>
      <div className="flex flex-row mr-20">
        <a
          href="https://www.instagram.com/rapidrentalsinsta/"
          target="_blank"
          className="link link-hover">
          <InstagremIcon />
        </a>
        <a
          href="https://www.facebook.com/RapidRentalsFB"
          target="_blank"
          className="link link-hover">
          <FacebookIcon />
        </a>
        <a
          href="https://x.com/RapidRentalsX"
          target="_blank"
          className="link link-hover">
          <TwitterXIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/rapidrentals"
          target="_blank"
          className="link link-hover">
          <LinkedInIcon />
        </a>
      </div>
      <nav>
        <a
          href={routesSettings.MAIN_TERMS_AND_CONDITIONS.path}
          target="_blank"
          className="link link-hover">
          Terms of use
        </a>
        <a
          href={routesSettings.MAIN_PRIVACY_POLICY.path}
          target="_blank"
          className="link link-hover">
          Privacy policy
        </a>
        <a
          href="https://www.valarisolutions.com/"
          target="_blank"
          className="link link-hover">
          Developed by Valari Solutions
        </a>
      </nav>
    </footer>
  );
};

export default FooterView;
