import { ErrorMessage, Field, FieldProps } from 'formik';
import { FC } from 'react';
import Label from '../Label';
import { TextInputProps } from './TextInput.props';

const TextInputView: FC<TextInputProps> = (props) => {
  const {
    label,
    isRequired,
    name,
    disabled,
    placeholder,
    tooltipMessage,
    hidden = false,
    children,
    type,
    isReadOnly,
    hideErrorComponent = false,
    innerRef,
    className,
    onKeyUp,
    onFocus,
  } = props;
  return (
    <div className={hidden ? 'hidden' : 'w-full'}>
      {label && (
        <Label
          name={name}
          isRequired={isRequired}
          toolTipMessage={tooltipMessage}
          title={label!}
        />
      )}
      <Field
        id={label}
        type={type}
        className={`${className} w-full bg-white`}
        name={name}
        innerRef={innerRef}>
        {({ field }: FieldProps) => (
          <input
            {...field}
            onFocus={onFocus}
            type={type}
            placeholder={placeholder ? placeholder : ''}
            className={`text-input input ${className}`}
            disabled={disabled || isReadOnly}
            onKeyUp={onKeyUp}
          />
        )}
      </Field>
      {children}
      {!hideErrorComponent && (
        <div className={`h-5 my-1`}>
          <ErrorMessage
            component="div"
            className="text-red-500 text-sm whitespace-nowrap"
            name={name}
          />
        </div>
      )}
    </div>
  );
};

export default TextInputView;
