import { FC, useRef, useState } from 'react';

import ChevronLeft from 'components/icons/ChevronLeft';
import ChevronRight from 'components/icons/ChevronRight';
import DateIcon from 'components/icons/DateIcon';
import { format } from 'date-fns';
import { useField } from 'formik';
import 'react-calendar/dist/Calendar.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { FormikCalendarProps } from './FormikCalendar.props';
import { CustomCalendar } from './FormikCalendar.style';

const FormikCalendarView: FC<FormikCalendarProps> = (props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const { onChange, tileDisabled, tileContent, name } = props;

  const [dateRangeField, _, helper] = useField(name);

  const displayValue =
    startDate && endDate
      ? `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`
      : 'Select Date';

  return (
    <div className="dropdown">
      <div
        style={{
          height: '10px !important',
        }}
        tabIndex={0}
        role="button"
        className="btn p-0">
        <div className="flex gap-4 h-full ">
          <div className="bg-secondary-900 h-full w-12 rounded-l-md flex justify-center items-center">
            <DateIcon />
          </div>
          <div className="flex justify-center items-center text-lg font-light text-gray-600 mr-4">
            {displayValue}
          </div>
        </div>
      </div>
      <div
        tabIndex={0}
        className="menu mt-2 dropdown-content z-[1] md:w-[450px] w-[350px] h-auto shadow-lg shadow-[#00000040] border bg-white rounded-lg py-4 -ml-8">
        <CustomCalendar
          {...props}
          calendarType="gregory"
          prevLabel={<ChevronLeft h="12" w="12" />}
          nextLabel={<ChevronRight h="12" w="12" />}
          next2Label={null}
          prev2Label={null}
          selectRange={true}
          brandColor="#E7C0ED"
          onChange={(dates: Value) => {
            if (!Array.isArray(dates)) {
              return;
            }
            let startDate = dates[0] as Date;
            let endDate = dates[1] as Date;
            setStartDate(startDate!);
            setEndDate(endDate!);
            helper.setValue([startDate, endDate]);
            onChange && onChange([startDate, endDate]);
          }}
        />
      </div>
    </div>
  );
};

export default FormikCalendarView;
