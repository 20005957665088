import { IconProps } from './type';

const LinkedInIcon = (props: IconProps) => {
  return (
    <svg
      width={props.w || '30'}
      height={props.h || '30'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        d="M4.98 3.5C4.98 4.88 3.88 6 2.5 6S0 4.88 0 3.5 1.12 1 2.5 1s2.48 1.12 2.48 2.5zM.5 8h4v13h-4V8zm7 0h4v2h.06c.56-1.04 1.94-2.13 3.94-2.13 4.22 0 5 2.78 5 6.38V21h-4v-5.75c0-1.36-.03-3.11-2-3.11-2 0-2.31 1.5-2.31 3v5.86h-4V8z"
        fill="#22cc7a"
      />
    </svg>
  );
};

export default LinkedInIcon;
