import { FC, useState } from 'react';

import ArrowLeft from 'components/icons/ArrowLeft';
import ArrowRight from 'components/icons/ArrowRight';
import { getNetworkImage } from 'utils/assets';
import { ImageShowcaseProps } from './ImageShowcase.props';

const ImageShowcaseView: FC<ImageShowcaseProps> = ({
  images,
  featuredImage,
  contollButtonsSize,
}) => {
  featuredImage = featuredImage ?? images[0];
  const [currentImageIndex, setCurrentImage] = useState(0);
  const allImage = Array.from(new Set([...[featuredImage], ...images]));
  return (
    <div className="space-y-4">
      <div className="space-y-4">
        <div
          className="w-full h-full aspect-square rounded-lg border-[1px] border-gray-100 bg-center bg-cover min-h-[200px] relative"
          style={{
            backgroundImage: `url(${getNetworkImage(allImage[currentImageIndex]!)})`,
          }}>
          <div className="flex w-full h-full justify-between space-x-8 absolute items-center px-4">
            <div
              className="bg-black/50 p-2 rounded-full flex justify-center items-center cursor-pointer  transform transition-transform hover:scale-110 hover:-translate-x-1"
              onClick={() => {
                if (currentImageIndex > 0) {
                  setCurrentImage((prev) => prev - 1);
                } else {
                  setCurrentImage(allImage.length - 1);
                }
              }}>
              <ArrowLeft size={contollButtonsSize?.toString()} color="#fff" />
            </div>
            <div
              className="bg-black/50 p-2 rounded-full flex justify-center items-center cursor-pointer  transform transition-transform hover:scale-110 hover:translate-x-1"
              onClick={() => {
                if (currentImageIndex == allImage.length - 1) {
                  setCurrentImage(0);
                } else {
                  setCurrentImage((prev) => prev + 1);
                }
              }}>
              <ArrowRight size={contollButtonsSize?.toString()} color="#fff" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-full space-y-4">
        {/* <div className="flex w-full h-full justify-center space-x-8">
          <div
            onClick={() =>
              currentImageIndex > 0 && setCurrentImage((prev) => prev - 1)
            }>
            <ArrowLeft />
          </div>
          <div
            onClick={() =>
              currentImageIndex < allImage.length - 1 &&
              setCurrentImage((prev) => prev + 1)
            }>
            <ArrowRight />
          </div>
        </div> */}
        <div className="flex flex-row overflow-scroll hide-scrollbar max-h-[500px] gap-4 w-full">
          {allImage &&
            allImage?.map((photo, index) => (
              <img
                key={`image-${index}`}
                style={{ height: '100px', width: '100px' }}
                className={`rounded-lg  object-center aspect-square object-cover hover:cursor-pointer box-border ${currentImageIndex == index ? 'border-solid border-2 border-gray-400' : ' border-[1px]'} `}
                onClick={() => setCurrentImage(index)}
                src={getNetworkImage(photo)}
                alt=""
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageShowcaseView;
