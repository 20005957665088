import { FC, useState } from 'react';

import useRentAvailabilityMutation from 'api/mutations/useRentAvailabilityMutation';
import FormikCalendar from 'components/form/FormikCalendar';
import Button from 'components/primitives/Button';
import { routesSettings } from 'constants/routes';
import { ErrorMessage, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { $session } from 'state/stores';
import { $itemRentDetails } from 'state/stores/rent-details';
import DateTimeZ from 'utils/dateTime';
import { validationSchema } from '../ProductToRent/utils';
import { RentFormProps } from './RentForm.props';

const RentFormView: FC<RentFormProps> = ({
  handleRentClick,
  isRentNowDisabled = false,
  submitButtonTitle = 'Rent Now',
}) => {
  const [dateRange, setDateRange] = useState<(Date | null)[]>([]);

  const rentAvailability = $itemRentDetails.selectors.useRentAvailability();
  const product = $itemRentDetails.selectors.useProduct();
  const itemId = product?._id;

  const initialValues = {
    dateRange: dateRange, // Set initial value as the date range
  };
  const navigate = useNavigate();

  const { mutateAsync: fetchRentAvailability, isPending } =
    useRentAvailabilityMutation();

  const disabledDates =
    rentAvailability?.map((e) => {
      const eDate = new Date(e.date);
      return (
        eDate.getFullYear() + '-' + eDate.getMonth() + '-' + eDate.getDate()
      );
    }) ?? [];

  /**
   * Checks if a given date is disabled.
   *
   * @param {Date} date - The date to check.
   * @return {boolean} Returns true if the date is disabled, false otherwise.
   */
  const disableDates = (date: Date) => {
    return disabledDates.includes(
      date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate(),
    );
  };

  /**
   * Checks if the item is available for rent within the specified date range.
   *
   * @return {boolean} True if the item is available, false otherwise.
   */
  const isItemAvailable = (newDates?: string[]) => {
    let startDate = new Date(dateRange![0]!); // Start from the startDate
    let endDate = new Date(dateRange![1]!); // Start from the startDate
    let disabledDatesMap = new Set();
    let dates = newDates ?? disabledDates;
    for (let i = 0; i < dates.length; i++) disabledDatesMap.add(dates[i]);
    if (disabledDatesMap.size === 0) return true;
    while (startDate <= endDate) {
      const startDateString =
        startDate.getFullYear() +
        '-' +
        startDate.getMonth() +
        '-' +
        startDate.getDate();
      if (disabledDatesMap.has(startDateString)) {
        return false;
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    return true;
  };

  const isActive = $session.selectors.useIsAuth();
  const validateSession = () => {
    if (!isActive) {
      navigate(routesSettings.AUTH_LOGIN.path, {
        replace: true,
      });
      return false;
    }
    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (data, { setErrors }) => {
        if (!validateSession()) return;
        const startDate = new DateTimeZ(data.dateRange[0]?.toDateString());
        const endDate = new DateTimeZ(data.dateRange[1]?.toDateString());

        if (startDate.formatDate() === endDate.formatDate()) {
          setErrors({
            dateRange:
              'Please adjust the dates to meet the 2-day minimum rental period',
          });
          return;
        }

        const currentDate = new DateTimeZ().defaultPayloadStringFormat();
        fetchRentAvailability({
          id: itemId!,
          startDate: currentDate,
          endDate: endDate.addMonths(6).defaultPayloadStringFormat(),
        }).then((data: any) => {
          $itemRentDetails.actions.setRentAvailability(data);
          const dates = data.map((e: any) => {
            const eDate = new Date(e.date);
            return (
              eDate.getFullYear() +
              '-' +
              eDate.getMonth() +
              '-' +
              eDate.getDate()
            );
          });
          const isAvailable = isItemAvailable(dates);

          if (!isAvailable) {
            setErrors({
              dateRange: 'Item is not available for the selected dates',
            });
            return;
          }
          handleRentClick && handleRentClick(dateRange);
        });
      }}>
      {({ errors, setFieldValue }) => {
        return (
          <div className="w-full">
            <Form className="flex lg:flex-row flex-col gap-4">
              <FormikCalendar
                onChange={(data) => {
                  setDateRange(data as Date[]);
                }}
                name="dateRange"
                minDate={new Date()}
                tileDisabled={(date) => {
                  return disableDates(date.date) ?? false;
                }}
                tileContent={(date) => {
                  return date.view === 'month' ? <div /> : null;
                }}
              />
              <div className="">
                <Button
                  isLoading={isPending}
                  isDisabled={isRentNowDisabled}
                  variant={'solid'}
                  colorScheme={'primary'}
                  type="submit">
                  {submitButtonTitle}
                </Button>
              </div>
            </Form>
            <div className="mt-2">
              <ErrorMessage
                component="div"
                className="text-red-500 text-sm"
                name={'dateRange'}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default RentFormView;
