import { ComponentType } from 'react';

import { useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

const defaultOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5, ease: 'easeIn' },
};

const withMotion = (
  WrappedComponent: ComponentType<any>,
  animateOptions?: any,
) => {
  const options = animateOptions || defaultOptions;

  const AnimatedPage: ComponentType<any> = (props) => {
    const location = useLocation();
    return (
      <AnimatePresence mode="wait">
        <motion.div
          className="h-full"
          key={location.key}
          initial={options.initial}
          animate={options.animate}
          exit={options.exit}
          transition={options.transition}>
          <WrappedComponent {...props} />
        </motion.div>
      </AnimatePresence>
    );
  };

  return AnimatedPage;
};

export default withMotion;
