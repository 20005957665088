function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <mask
        id="mask0_2610_100561"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H32V32H0z"></path>
      </mask>
      <g mask="url(#mask0_2610_100561)">
        <path
          fill="#FF4747"
          d="M16 17.33c.377 0 .694-.127.95-.383.255-.255.383-.572.383-.95v-5.366c0-.378-.128-.69-.383-.934a1.323 1.323 0 00-.95-.366 1.29 1.29 0 00-.95.382 1.291 1.291 0 00-.384.951v5.367c0 .378.128.689.384.933.255.245.572.367.95.367zm0 5.334c.377 0 .694-.128.95-.384a1.29 1.29 0 00.383-.95c0-.377-.128-.694-.383-.95a1.294 1.294 0 00-.95-.383 1.29 1.29 0 00-.95.383 1.291 1.291 0 00-.384.95c0 .379.128.695.384.95.255.256.572.384.95.384zm0 6.667c-1.845 0-3.578-.35-5.2-1.05a13.457 13.457 0 01-4.234-2.85c-1.2-1.2-2.15-2.611-2.85-4.234-.7-1.622-1.05-3.355-1.05-5.2 0-1.844.35-3.577 1.05-5.2.7-1.622 1.65-3.033 2.85-4.233 1.2-1.2 2.611-2.15 4.233-2.85 1.623-.7 3.356-1.05 5.2-1.05 1.845 0 3.578.35 5.2 1.05 1.623.7 3.034 1.65 4.234 2.85 1.2 1.2 2.15 2.611 2.849 4.233.7 1.623 1.05 3.356 1.05 5.2 0 1.845-.35 3.578-1.05 5.2a13.455 13.455 0 01-2.85 4.234c-1.2 1.2-2.61 2.15-4.233 2.85-1.622.7-3.355 1.05-5.2 1.05z"></path>
      </g>
    </svg>
  );
}

export default ErrorIcon;
